import { useNavigate, useMatch } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';

import { ROUTES } from '../constants';

const MainNavLink = ({ route }: any) => {
  const navigate = useNavigate();
  const isMatch = useMatch(route.path);

  return (
    <MenuItem selected={Boolean(isMatch)} onClick={() => navigate(route.path)}>
      {route.label}
    </MenuItem>
  );
};

export const mainNavLinks = Object.values(ROUTES).reduce(
  (routeList: React.ReactNode[], route, idx) => {
    if (route.mainNav) {
      routeList.push(<MainNavLink key={idx} route={route} />);
    }
    return routeList;
  },
  [] as React.ReactNode[]
);
