import { FallbackProps } from 'react-error-boundary';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import ErrorIcon from '@mui/icons-material/Error';

import { Footer } from '../components';
import { CONFIG } from '../utilities';
import { logger } from '../logging';

export const GlobalErrorFallback = ({
  error,
  resetErrorBoundary,
}: FallbackProps) => (
  <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
    <Paper
      variant="outlined"
      sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
    >
      <Box sx={{ width: '100%' }}>
        <Stack alignItems="center">
          <ErrorIcon
            sx={{
              fontSize: '80px',
              color: 'error.dark',
            }}
            fontSize="large"
          />
          <Typography variant="h5">
            Ahhhhhhhhhhhhh! ...something went wrong:
          </Typography>
          <Box
            component="img"
            sx={{ mt: 4, mb: 2, maxWidth: '100%' }}
            alt="Ahhhhhhhh!"
            src={`${CONFIG.jarvisApiUrl}/screaming-goat.jpg`}
          />
          <Button
            onClick={() => {
              logger.info('Global Error Boundary Reset', error);
              resetErrorBoundary();
            }}
            variant="outlined"
          >
            Try Again
          </Button>
          <p>{error.message}</p>
          {CONFIG.isDevelopment && <p>{error.stack}</p>}
        </Stack>
      </Box>
    </Paper>
    <Footer />
  </Container>
);
