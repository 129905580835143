import React from 'react';
import { useTheme, styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete, {
  autocompleteClasses,
  AutocompleteProps,
} from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import Popper from '@mui/material/Popper';
import {
  FieldError,
  Controller,
  Control,
  Path,
  UseControllerReturn,
} from 'react-hook-form';
import { VariableSizeList, ListChildComponentProps } from 'react-window';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

export type AutocompleteFieldProps<TFormValues, TOption> = Partial<
  Omit<AutocompleteProps<TOption, boolean, false, false>, 'onPaste'>
> & {
  control: Control<TFormValues>;
  name: Path<TFormValues>;
  error?: FieldError | FieldError[];
  label: string;
  getOptions?: (currentValues: TOption[]) => TOption[];
  helperText?: string;
  hidden?: boolean;
  loading?: boolean;
  onPaste?: (
    e: React.ClipboardEvent<HTMLDivElement>,
    onChange: UseControllerReturn<TFormValues>['field']['onChange'],
    value: UseControllerReturn<TFormValues>['field']['value']
  ) => void;
  options?: TOption[];
};

type ListboxProps = React.HTMLAttributes<HTMLUListElement> & {
  // It's unfortunate that @mui typings for ListboxComponent and ListboxProps don't allow this to be required...
  getOptionLabel?: AutocompleteFieldProps<any, any>['getOptionLabel'];
};

const LISTBOX_PADDING = 8; // px

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function renderRow(props: ListChildComponentProps) {
  const {
    data: {
      itemData,
      customProps: { getOptionLabel },
    },
    index,
    style,
  } = props;
  const dataSet = itemData[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  };

  if (dataSet.hasOwnProperty('group')) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }

  const [defaultProps, option, { selected, inputValue }] = dataSet;

  // Search Match Highlighting
  const optionLabel = getOptionLabel ? getOptionLabel(option) : option.label;
  const matches = match(optionLabel, inputValue, {
    insideWords: true,
  });
  const parts = parse(optionLabel, matches);

  return (
    <Typography
      component="li"
      key={defaultProps.id}
      {...defaultProps}
      noWrap
      style={inlineStyle}
    >
      <Checkbox
        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
        checkedIcon={<CheckBoxIcon fontSize="small" />}
        style={{ marginRight: 8 }}
        checked={selected}
      />
      {parts.map((part: any, index: number) => (
        <span
          key={index}
          style={{
            fontWeight: part.highlight ? 700 : 400,
          }}
        >
          {part.text}
        </span>
      ))}
    </Typography>
  );
}

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement> & Pick<ListboxProps, 'getOptionLabel'>
>(function ListboxComponent(props, ref) {
  const { children, getOptionLabel, ...other } = props;
  const itemData: React.ReactChild[] = [];
  (children as React.ReactChild[]).forEach(
    (item: React.ReactChild & { children?: React.ReactChild[] }) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    }
  );

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child: React.ReactChild) => {
    if (child.hasOwnProperty('group')) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={{ itemData, customProps: { getOptionLabel } }}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index: any) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

export const AutocompleteField = <
  TFormValues extends Record<string, unknown>,
  TOption extends any
>({
  control,
  error,
  filterOptions,
  getOptionLabel,
  getOptions,
  groupBy,
  helperText = ' ',
  hidden,
  isOptionEqualToValue,
  label,
  loading,
  multiple,
  name,
  onClose,
  onInputChange,
  onOpen,
  onPaste = () => {
    /** NOOP */
  },
  options,
  renderInput,
}: AutocompleteFieldProps<TFormValues, TOption>) => {
  const errorMessages = Array.isArray(error)
    ? error.map((e, idx) => (
        <React.Fragment key={idx}>
          {e.message}
          <br />
        </React.Fragment>
      ))
    : error?.message;
  return hidden ? null : (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        React.useEffect(() => {
          if (multiple) {
            field.onChange({ target: { value: [] } });
          }

          if (!multiple) {
            field.onChange({ target: { value: [null] } });
          }
        }, [multiple]);

        return (
          <FormControl fullWidth sx={{ mt: 2 }} error={Boolean(error)}>
            <Autocomplete
              autoComplete
              disableCloseOnSelect
              disablePortal
              filterOptions={filterOptions}
              getOptionLabel={getOptionLabel}
              groupBy={groupBy}
              id={'autocomplete_search'}
              isOptionEqualToValue={isOptionEqualToValue}
              ListboxComponent={ListboxComponent}
              ListboxProps={{ getOptionLabel } as ListboxProps}
              loading={loading}
              multiple={multiple}
              onChange={(_, newValue) => {
                const isArray = Array.isArray(newValue);

                field.onChange(isArray ? newValue : [newValue]);
              }}
              onClose={onClose}
              onInputChange={onInputChange}
              onOpen={onOpen}
              onPaste={(e) => onPaste(e, field.onChange, field.value)}
              options={
                options ??
                (getOptions ? getOptions(field.value as TOption[]) : [])
              }
              PopperComponent={StyledPopper}
              renderGroup={(params) => params}
              renderInput={
                renderInput ??
                ((params) => (
                  <TextField
                    {...params}
                    error={Boolean(error)}
                    helperText={errorMessages ?? helperText}
                    label={label}
                    sx={{ m: 0 }}
                  />
                ))
              }
              renderOption={(...args) => args}
              value={multiple ? field.value : (field.value as any[])[0]}
            />
          </FormControl>
        );
      }}
    />
  );
};
