import React from 'react';
import * as yup from 'yup';
import { useForm, SubmitHandler } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@mui/material/Button';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import { logger } from '../../logging';
import {
  ANALYSIS_TYPES,
  BG_BLANKET_ACCOUNT,
  FORM_TITLES,
  MARKUP_TYPES,
  RATE_TYPES,
  REQUEST_TYPES,
} from '../constants';
import {
  ExcelUploadField,
  StatesField,
  SubmissionButton,
  ZipCodeField,
} from '../../components';
import { jarvisApi } from '../../api';
import { useAuth } from '../../auth';
import { CONTAINERS } from '../../../../shared-jarvis';
import {
  AnalysisTypeField,
  CarrierListField,
  CarrierTiersField,
  EnterpriseSearchField,
  FileNameField,
  ImpactScacRestrictionsField,
  MarkupAmountField,
  MarkupPercentageTypeField,
  MarkupTypeField,
  NotifyEmailListField,
  RateTypeField,
  RequestTypesField,
} from '../fields';
import { analysisFieldSchema } from '../analysisFieldSchema';
import { analysisRequestColumnBuilders } from '.';
import {
  withUploadToBlobStorage,
  WithUploadToBlobStorageProps,
} from '../withUploadToBlobStorage';

const schema = yup.object({
  // TODO: Better excel file validation
  // https://stackoverflow.com/questions/62515683/yup-w-formik-file-upload-validation
  shipmentsFileUpload: yup
    .array()
    .of(yup.mixed<File>().required('File is required'))
    .required('File is required'),
  ...analysisFieldSchema,
});

export type RateRequestFormFields = yup.InferType<typeof schema>;

const Form = ({
  handleFormSubmit,
  submitting,
}: WithUploadToBlobStorageProps<RateRequestFormFields>) => {
  const auth = useAuth();

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    resetField,
  } = useForm<RateRequestFormFields>({
    resolver: yupResolver(schema),
    defaultValues: {
      analysisType: ANALYSIS_TYPES.LEAST_COST_CARRIER,
      carrierTiers: [],
      carrierExclusions: [],
      carrierInclusions: [],
      originStateExclusions: [],
      destinationStateExclusions: [],
      accounts: [null],
      notifyEmails: [{ value: auth.user?.email }],
    },
    shouldUnregister: true,
  });

  const selectedRequestType = watch('requestType');
  const selectedRateType = watch('rateType');
  const selectedMarkupType = watch('markupType');
  const selectedAnalysisType = watch('analysisType');
  const selectedAccounts = watch('accounts');
  const selectedCarrierTiers = watch('carrierTiers');

  // Request Type selection side-effects
  React.useEffect(() => {
    if (selectedRequestType === REQUEST_TYPES.BLANKETS) {
      setValue('rateType', RATE_TYPES.CARRIER);
      setValue('accounts', [BG_BLANKET_ACCOUNT]);
      resetField('markupType');
    }

    if (selectedRequestType === REQUEST_TYPES.ASP) {
      setValue('markupType', MARKUP_TYPES.CURRENT);
      setValue(
        'accounts',
        selectedAccounts.filter(
          (selectedAccount) =>
            selectedAccount?.accountNumber !== BG_BLANKET_ACCOUNT.accountNumber
        )
      );
    }
  }, [selectedRequestType, setValue]);

  const onSubmit: SubmitHandler<RateRequestFormFields> = async (formData) => {
    handleFormSubmit(
      formData,
      CONTAINERS.RATEQUALITY_SHIPMENTS_RATING_ANALYSIS,
      analysisRequestColumnBuilders
    );
  };

  return (
    <>
      <Typography
        align="center"
        component="h1"
        variant="h4"
        sx={{ mb: 3, mt: 2 }}
      >
        {FORM_TITLES.RATING_ANALYSIS}
      </Typography>

      <Typography align="center">
        <Button
          variant="outlined"
          startIcon={<FileDownloadIcon />}
          onClick={async () => {
            try {
              await jarvisApi.fetchDataUploadTemplate();
              logger.info('Download Success: JARVIS Data Upload Template');
            } catch (e: any) {
              logger.error('Download Error: JARVIS Data Upload Tempalte', {
                error: { message: e.message, stack: e.stack },
              });
            }
          }}
        >
          Data Upload Template
        </Button>
      </Typography>

      <form
        style={{ marginTop: '1rem', marginBottom: 0 }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <FileNameField<RateRequestFormFields>
          control={control}
          name="fileName"
          error={errors.fileName}
        />

        <ExcelUploadField
          label="Shipment Data"
          name="shipmentsFileUpload"
          control={control}
          error={errors.shipmentsFileUpload}
        />

        <RequestTypesField<RateRequestFormFields>
          control={control}
          name="requestType"
          error={errors.requestType}
        />

        <RateTypeField<RateRequestFormFields>
          disabled={selectedRequestType === REQUEST_TYPES.BLANKETS}
          control={control}
          name="rateType"
          error={errors.rateType}
        />

        <EnterpriseSearchField<RateRequestFormFields>
          control={control}
          error={errors.accounts as any}
          helperText="Search by Account Name or Number"
          label="Accounts"
          multiple
          name="accounts"
          user={auth.user!}
        />

        <CarrierTiersField<RateRequestFormFields>
          control={control}
          name="carrierTiers"
          error={errors.carrierTiers}
        />

        <CarrierListField<RateRequestFormFields>
          control={control}
          error={errors.carrierExclusions}
          filterOptions={(options) =>
            options.filter((opt) =>
              selectedCarrierTiers?.includes(opt.carrier.tier)
            )
          }
          label="Exclude Carriers"
          name="carrierExclusions"
        />

        <CarrierListField<RateRequestFormFields>
          control={control}
          error={errors.carrierExclusions}
          filterOptions={(options) =>
            options.filter(
              (opt) => !selectedCarrierTiers?.includes(opt.carrier.tier)
            )
          }
          label="Include Carriers"
          name="carrierInclusions"
        />

        <AnalysisTypeField<RateRequestFormFields>
          control={control}
          name="analysisType"
          error={errors.analysisType}
        />

        <ImpactScacRestrictionsField<RateRequestFormFields>
          control={control}
          error={errors.impactScacRestrictions}
          hidden={selectedAnalysisType !== ANALYSIS_TYPES.IMPACT_ANALYSIS}
          name="impactScacRestrictions"
        />

        <ZipCodeField<RateRequestFormFields>
          control={control}
          error={errors.originZipExclusions}
          hidden={selectedAnalysisType !== ANALYSIS_TYPES.IMPACT_ANALYSIS}
          label="Origin Zip Exclusions"
          name="originZipExclusions"
        />

        <StatesField<RateRequestFormFields>
          control={control}
          name="originStateExclusions"
          label="Origin State/Province Exclusions"
          hidden={selectedAnalysisType !== ANALYSIS_TYPES.IMPACT_ANALYSIS}
        />

        <ZipCodeField<RateRequestFormFields>
          control={control}
          error={errors.destinationZipExclusions}
          hidden={selectedAnalysisType !== ANALYSIS_TYPES.IMPACT_ANALYSIS}
          label="Destination Zip Exclusions"
          name="destinationZipExclusions"
        />

        <StatesField<RateRequestFormFields>
          control={control}
          name="destinationStateExclusions"
          label="Destination State/Province Exclusions"
          hidden={selectedAnalysisType !== ANALYSIS_TYPES.IMPACT_ANALYSIS}
        />

        <MarkupTypeField<RateRequestFormFields>
          control={control}
          error={errors.markupType}
          filterOptions={(options) => {
            if (selectedRequestType === REQUEST_TYPES.BLANKETS) {
              return options.filter((o) => o.value !== MARKUP_TYPES.CURRENT);
            }

            return options;
          }}
          hidden={selectedRateType !== RATE_TYPES.CARRIER}
          name="markupType"
        />

        <MarkupPercentageTypeField<RateRequestFormFields>
          control={control}
          hidden={selectedMarkupType !== MARKUP_TYPES.PERCENT}
          name="markupPercentType"
          error={errors.markupPercentType}
        />

        <MarkupAmountField<RateRequestFormFields>
          control={control}
          name="markupAmount"
          error={errors.markupAmount}
          amountType={
            selectedMarkupType === MARKUP_TYPES.PERCENT
              ? 'Percentage'
              : 'Dollar'
          }
          hidden={
            selectedRateType !== RATE_TYPES.CARRIER ||
            // selectedMarkupType === MARKUP_TYPES.SEGMENT ||
            selectedMarkupType === MARKUP_TYPES.CURRENT
          }
        />

        <NotifyEmailListField<RateRequestFormFields>
          control={control}
          error={errors.notifyEmails}
          name="notifyEmails"
          hidden={!auth.isBGEmployee}
        />

        <SubmissionButton
          loading={submitting}
          buttonProps={{
            sx: { mt: 3, p: 2 },
            fullWidth: true,
            type: 'submit',
          }}
        />
      </form>
    </>
  );
};

export const UploadAnalyzeForm = withUploadToBlobStorage(Form);
