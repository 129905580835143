import { AxiosError, AxiosResponse } from 'axios';

export const handleResponse = (response: AxiosResponse) => {
  if (response?.data) {
    return response.data;
  }

  return response;
};

export const handleError = (error: AxiosError) => {
  if (error?.response) {
    return error.response;
  }

  return error;
};
