import React from 'react';
import { wrap, releaseProxy } from 'comlink';

export const useExcelWorkerApi = () => {
  const { excelWorkerApi, cleanup } = React.useMemo(
    () => makeExcelWorkerApiAndCleanup(),
    []
  );

  React.useEffect(() => {
    return () => {
      cleanup();
    };
  }, [cleanup]);

  return excelWorkerApi;
};

/**
 * Creates a the excel worker and it's cleanup function
 */
const makeExcelWorkerApiAndCleanup = () => {
  const worker = new Worker(
    new URL('../web-workers/excel-worker', import.meta.url),
    {
      name: 'excel-worker',
      type: 'module',
    }
  );
  const excelWorkerApi =
    wrap<import('../web-workers/excel-worker').ExcelWorkerType>(worker);

  const cleanup = () => {
    excelWorkerApi[releaseProxy]();
    worker.terminate();
  };

  const workerApiAndCleanup = { excelWorkerApi, cleanup };

  return workerApiAndCleanup;
};
