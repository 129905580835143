import sub from 'date-fns/sub';
import { Control, Path, FieldError } from 'react-hook-form';

import { DateRangePickerField } from '../../components';
import { TODAY } from '../../constants';

type PullDataDateRangeFieldProps<TFormValues> = {
  control: Control<TFormValues>;
  name: Path<TFormValues>;
  error?: {
    startDate?: FieldError;
    endDate?: FieldError;
  };
};

export const PullDataDateRangeField = <
  TFormValues extends Record<string, unknown>
>({
  control,
  name,
  error,
}: PullDataDateRangeFieldProps<TFormValues>) => (
  <DateRangePickerField<TFormValues>
    maxDateSpan={{ years: 1 }}
    startDateOptions={{
      minDate: sub(TODAY, { years: 3 }),
      maxDate: TODAY,
    }}
    endDateOptions={{
      minDate: sub(TODAY, { years: 3 }),
      maxDate: TODAY,
    }}
    control={control}
    openTo="year"
    name={name}
    error={error}
    label="Date Range"
    labelHelp={
      <>
        Maximum history is 3 years.
        <br /> Maximum time-span is 1 year.
      </>
    }
  />
);
