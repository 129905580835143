// Must be kept in sync with Azure Function code
// @see: https://dev.azure.com/bluegrace-vsts/Nebula/_git/BS.RateQuality?path=/BS.RateQuality.Functions/BatchRates/Entities
export const COLUMN_NAMES = {
  ACCOUNT_NAME: 'Account Name',
  ACCOUNT_NUMBER: 'Account Number',
  AUTO_RATE_SHIPMENTS: 'Auto Rate Shipments',
  DATE_RANGE: { FROM_DATE: 'From Date', TO_DATE: 'To Date' },
  EMAIL_RECIPIENTS: 'Email Recipients',
  SCAC_LIST: 'SCAC List',
} as const;
