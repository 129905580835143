import { Control, Path, FieldError } from 'react-hook-form';

import { DropdownField } from '../../components';
import { RATE_TYPES } from '../constants';
import { constToOption } from '../utilities';

const rateTypeOptions = Object.values(RATE_TYPES).map((v) => constToOption(v));

type RateTypeFieldProps<TFormValues> = {
  control: Control<TFormValues>;
  disabled?: boolean;
  error?: FieldError;
  helperText?: string;
  label?: string;
  name: Path<TFormValues>;
};

export const RateTypeField = <TFormValues extends Record<string, unknown>>({
  control,
  disabled = false,
  error,
  helperText,
  label,
  name,
}: RateTypeFieldProps<TFormValues>) => (
  <DropdownField<TFormValues>
    control={control}
    disabled={disabled}
    error={error}
    helperText={helperText}
    label={label ?? 'Rate Type'}
    name={name}
    options={rateTypeOptions}
  />
);
