import { Control, Path, FieldError } from 'react-hook-form';

import { DropdownField } from '../../components';
import { ANALYSIS_TYPES, ANALYSIS_TYPE_DETAILS } from '../constants';

const analysisTypeOptions = Object.values(ANALYSIS_TYPES).map((at) => ({
  value: at,
  helpText: ANALYSIS_TYPE_DETAILS[at],
}));

type AnalysisTypeFieldProps<TFormValues> = {
  control: Control<TFormValues>;
  error?: FieldError;
  helperText?: string;
  label?: string;
  name: Path<TFormValues>;
};

export const AnalysisTypeField = <TFormValues extends Record<string, unknown>>({
  control,
  error,
  helperText,
  label,
  name,
}: AnalysisTypeFieldProps<TFormValues>) => (
  <DropdownField<TFormValues>
    control={control}
    error={error}
    helperText={helperText}
    label={label ?? 'Analysis Type'}
    name={name}
    options={analysisTypeOptions}
  />
);
