import { Control, Path, FieldError, Controller } from 'react-hook-form';

import { ArrayField, TextField } from '../../components';

type NotifyEmailListFieldProps<TFormValues> = {
  control: Control<TFormValues>;
  error?: { value?: FieldError }[];
  helperText?: string;
  name: Path<TFormValues>;
  hidden?: boolean;
};

export const NotifyEmailListField = <
  TFormValues extends Record<string, unknown>
>({
  hidden = false,
  control,
  error,
  name,
}: NotifyEmailListFieldProps<TFormValues>) => (
  <ArrayField
    hidden={hidden}
    label="Emails to Notify"
    control={control}
    name={name}
    error={error}
    disableByIndex={[0]}
    renderItem={({ field: arrayField, index }) => ({
      fieldControl: (
        <Controller
          control={control}
          name={`${name}.${index}.value` as any}
          render={({ field }) => (
            <TextField
              disabled={index === 0}
              error={error?.[index]?.value}
              key={arrayField.id}
              label="Email"
              onChange={field.onChange}
              value={field.value}
            />
          )}
        />
      ),
      canEdit: !hidden,
      canRemove: index !== 0,
    })}
  />
);
