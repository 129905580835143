import { Control, Path, FieldError } from 'react-hook-form';

import { DropdownField } from '../../components';
import { MARKUP_TYPES, MARKUP_TYPES_LABELS } from '../constants';
import { constToOption } from '../utilities';

const markupTypeOptions = Object.values(MARKUP_TYPES).map((v) =>
  constToOption(v, MARKUP_TYPES_LABELS)
);

type MarkupTypeFieldProps<TFormValues> = {
  control: Control<TFormValues>;
  error?: FieldError;
  filterOptions?: (
    options: typeof markupTypeOptions
  ) => typeof markupTypeOptions;
  helperText?: string;
  hidden?: boolean;
  label?: string;
  name: Path<TFormValues>;
};

export const MarkupTypeField = <TFormValues extends Record<string, unknown>>({
  control,
  error,
  filterOptions,
  helperText,
  hidden,
  label,
  name,
}: MarkupTypeFieldProps<TFormValues>) => (
  <DropdownField<TFormValues>
    control={control}
    error={error}
    hidden={hidden}
    label={label ?? 'Markup Type'}
    name={name}
    options={
      filterOptions ? filterOptions(markupTypeOptions) : markupTypeOptions
    }
  />
);
