import { Controller, Control, Path, FieldError } from 'react-hook-form';
import { TextField } from '../../components';

type FileNameFieldProps<TFormValues> = {
  control: Control<TFormValues>;
  name: Path<TFormValues>;
  error?: FieldError;
};

export const FileNameField = <TFormValues extends Record<string, unknown>>({
  control,
  name,
  error,
}: FileNameFieldProps<TFormValues>) => (
  <Controller
    control={control}
    name={name}
    render={({ field }) => (
      <TextField
        error={error}
        helperText={
          error?.message ??
          `Name your results file - ${(field.value as string)?.length ?? 0}/50`
        }
        inputProps={{ maxLength: 50 }}
        label="Results File Name"
        onChange={field.onChange}
        sx={{ mb: 2, mt: 1 }}
      />
    )}
  />
);
