"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CONTAINERS = void 0;
exports.CONTAINERS = {
    RATEQUALITY_SHIPMENTS_AP_TESTING: 'ratequality-shipments-ap-testing',
    RATEQUALITY_SHIPMENTS_BATCH: 'ratequality-shipments-batch',
    RATEQUALITY_SHIPMENTS_CARRIER_RATE_COMPARE: 'ratequality-shipments-carrier-rate-compare',
    RATEQUALITY_SHIPMENTS_GEO_MILEAGE: 'ratequality-shipments-geo-mileage',
    RATEQUALITY_SHIPMENTS_RATING_ANALYSIS: 'ratequality-shipments-rating-analysis',
    RATEQUALITY_SHIPMENTS_RETRIEVAL: 'ratequality-shipments-retrieval',
};
