import axios from 'axios';

export const instance = axios.create();

instance.interceptors.request.use(
  (config) => {
    const userName = localStorage.getItem('BGUserName');
    const userId = localStorage.getItem('BGUserId');
    const userEnterpriseId = localStorage.getItem('BGUserEnterpriseId');

    if (!userName || !userId || !userEnterpriseId) {
      return config;
    }

    if (config && config.headers) {
      config.headers['UserName'] = userName;
      config.headers['UserId'] = userId;
      config.headers['UserEnterpriseId'] = userEnterpriseId;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
