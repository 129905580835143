import React from 'react';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { FieldError, InternalFieldErrors } from 'react-hook-form';
import { debounce, uniqBy } from 'lodash';

import { AutocompleteField, AutocompleteFieldProps } from './AutocompleteField';

type AsyncTypeAheadProps<TFormValues, TOption> = Omit<
  AutocompleteFieldProps<TFormValues, TOption>,
  'options'
> & {
  error?:
    | FieldError
    | InternalFieldErrors
    | FieldError[]
    | InternalFieldErrors[];
  minimumSearchChar?: number;
  optionIdentityKey: string;
  searchEndpoint: (searchString: string) => Promise<TOption[]>;
};

export const AsyncTypeAhead = <
  TFormValues extends Record<string, unknown>,
  TOption extends any
>({
  control,
  error,
  getOptionLabel,
  helperText = ' ',
  hidden,
  isOptionEqualToValue,
  label,
  minimumSearchChar = 2,
  multiple,
  name,
  optionIdentityKey,
  searchEndpoint,
}: AsyncTypeAheadProps<TFormValues, TOption>) => {
  const [open, setOpen] = React.useState(false);
  const [serverOptions, setOptions] = React.useState<any[]>([]);
  const [isSearching, setIsSearching] = React.useState(false);

  const searchCache: Record<string, string[]> = {};
  const isLoading = open && isSearching;

  const performSearch = React.useCallback(
    debounce(
      async (inputValue: string, minimumSearchChar: number, searchEndpoint) => {
        if (inputValue.length <= minimumSearchChar) return;
        setIsSearching(true);
        const cachedSearch = searchCache[inputValue];
        const results =
          cachedSearch ??
          (await (searchEndpoint ? searchEndpoint(inputValue) : []));
        if (Array.isArray(results)) {
          searchCache[inputValue] = results;
          setOptions(results);
        }
        setIsSearching(false);
      },
      300
    ),
    []
  );

  const performPaste = React.useCallback(
    async (e, onChange, currentValue) => {
      const pastedData = e.clipboardData.getData('Text');
      const searchStrings = pastedData
        .split(',')
        .map((an: string) => an.trim());

      setIsSearching(true);

      const results = await Promise.all(
        searchStrings.map((an: string) => searchEndpoint!(an))
      );
      const foundRecords = results.flatMap((r) => r);
      const newValue = currentValue
        .filter((v: any) => v !== null)
        .concat(multiple ? foundRecords : foundRecords[0]);

      if (results.length > 0) {
        onChange(newValue);
      }

      setIsSearching(false);
    },
    [multiple]
  );

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const errorMessages = Array.isArray(error)
    ? error.map((e, idx) => (
        <React.Fragment key={idx}>
          {e.message}
          <br />
        </React.Fragment>
      ))
    : error?.message;

  // Need to include any currently selected value(s) in options when the search string changes since filtering
  // is being handled server-side.
  const getOptions = (selectedValues: TOption[]): TOption[] =>
    multiple
      ? uniqBy(
          [
            ...serverOptions,
            ...((selectedValues as any[])[0] ? (selectedValues as any[]) : []),
          ],
          optionIdentityKey
        )
      : serverOptions;

  return !searchEndpoint ? null : (
    <AutocompleteField<
      TFormValues,
      Awaited<ReturnType<typeof searchEndpoint>>[number]
    >
      control={control}
      filterOptions={(x) => x}
      // filterOptions={() => options}
      getOptionLabel={getOptionLabel}
      getOptions={getOptions}
      hidden={hidden}
      isOptionEqualToValue={isOptionEqualToValue}
      label={label}
      loading={isLoading}
      multiple={multiple}
      name={name}
      onInputChange={(event, newInputValue) => {
        // Bail on selection events to not needlessly call the search endpoint
        if (event?.type === 'click') return;
        if (event?.type === 'keydown' && (event as any)?.key === 'Enter')
          return;
        performSearch(newInputValue, minimumSearchChar, searchEndpoint!);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onOpen={() => {
        setOpen(true);
      }}
      onPaste={(e, onChange, value) => performPaste(e, onChange, value)}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{ m: 0 }}
          error={Boolean(error)}
          label={label}
          helperText={errorMessages ?? helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};
