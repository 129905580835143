import * as yup from 'yup';
import { isValidZipCodeFormat } from './zipcodes';
import { STATE_OPTIONS } from '../../constants';
import { CARRIER_NAME } from '../constants';
import { CarrierOptionType } from '../fields';

export const validators = {
  zipCode: yup
    .string()
    .test(
      'valid-zipcode',
      'Must be comma separated, valid US or CA zip codes',
      (value) => {
        const result = value
          ?.split(',')
          .find((zip) => !isValidZipCodeFormat(zip.trim()));
        return !result;
      }
    ),
  email: yup.string().email('Must be a valid email address'),
  dateRange: yup
    .object({
      startDate: yup
        .date()
        .max(yup.ref('endDate'), 'Start date cannot be after the end date')
        .nullable(),
      endDate: yup
        .date()
        .min(yup.ref('startDate'), 'End date cannot be before the start date')
        .nullable(),
    })
    .test(
      'is-valid-date-range',
      'A Valid Date Range is Required',
      function (value) {
        const { path, createError } = this;
        if (!value.startDate) {
          return createError({
            path: `${path}.startDate`,
            message: 'Start Date is Required',
          });
        }
        if (!value.endDate) {
          return createError({
            path: `${path}.endDate`,
            message: 'End Date is Required',
          });
        }

        return true;
      }
    ),
  carrierName: yup.mixed<CARRIER_NAME>(),
  carrierList: yup.array<CarrierOptionType>(),
  state: yup.mixed<{
    label: typeof STATE_OPTIONS;
    value: typeof STATE_OPTIONS;
  }>(),
  get blugraceEmail() {
    return this.email.test(
      'is-valid-blugrace-email',
      'A valid @bluegracegroup.com or @mybluegrace.com is required',
      function (value) {
        const { path, createError } = this;
        const domain = value?.split('@')[1];

        if (domain !== 'bluegracegroup.com' && domain !== 'mybluegrace.com') {
          return createError({
            path,
            message:
              'A valid @bluegracegroup.com or @mybluegrace.com is required',
          });
        }

        return true;
      }
    );
  },
};
