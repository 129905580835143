import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

type UploadStatusProps = {
  statusMessage: { main: string; submessage: string };
};

export const UploadStatus = ({ statusMessage }: UploadStatusProps) => {
  return (
    <Dialog
      fullWidth
      open={Boolean(statusMessage.main)}
      onClick={() => {
        /** NOOP */
      }}
    >
      <DialogContent>
        <DialogContentText component="div" sx={{ textAlign: 'center' }}>
          <Typography variant="h4">{statusMessage.main}</Typography>
        </DialogContentText>
        {statusMessage.submessage && (
          <DialogContentText>{statusMessage.submessage}</DialogContentText>
        )}
        <LinearProgress sx={{ mt: 2 }} />
      </DialogContent>
    </Dialog>
  );
};
