import { PullDataAnalyzeFormFields } from './PullDataAnalyzeForm';
import { COLUMN_NAMES } from './columnNames';
import {
  analysisRequestColumnBuilders,
  RateRequestFormFields,
} from '../UploadAnalayzeForm';
import { WorkbookColumnBuilders } from '../../@types';

export const pullDataRequestColumnBuilders: WorkbookColumnBuilders<
  PullDataAnalyzeFormFields & RateRequestFormFields
> = {
  [COLUMN_NAMES.DATE_RANGE.FROM_DATE]: (formFields) => {
    const value = formFields.dateRange?.startDate;
    return value;
  },
  [COLUMN_NAMES.DATE_RANGE.TO_DATE]: (formFields) => {
    const value = formFields.dateRange?.endDate;
    return value;
  },
  [COLUMN_NAMES.EMAIL_RECIPIENTS]: (formFields) => {
    const value = formFields.notifyEmails
      .map((v: { value: string }) => v.value)
      .join(',');
    return value;
  },
  [COLUMN_NAMES.AUTO_RATE_SHIPMENTS]: (_) => {
    return 'True';
  },
  ...analysisRequestColumnBuilders,
  // Filter out
  'Notification Email': (_) => undefined,
};
