import * as yup from 'yup';

import { ValueOf } from '../@types';
import {
  ANALYSIS_TYPES,
  MARKUP_PERCENT_TYPES,
  MARKUP_TYPES,
  RATE_TYPES,
  REQUEST_TYPES,
  TIER_VALUES,
} from './constants';
import { validators } from './utilities';

export const analysisFieldSchema = {
  fileName: yup.string().required('Results file name is required'),
  requestType: yup
    .mixed<ValueOf<typeof REQUEST_TYPES>>()
    .oneOf(Object.values(REQUEST_TYPES))
    .required('Request Type is required'),
  rateType: yup
    .mixed<ValueOf<typeof RATE_TYPES>>()
    .oneOf(Object.values(RATE_TYPES))
    .required('Rate Type is required'),
  accounts: yup
    .array()
    .of(
      yup
        .object({
          name: yup.string().required(),
          enterpriseID: yup.number().required(),
          accountNumber: yup.string().required(),
        })
        .nullable() // work-around to set null as initial value for @mui
        .test(
          'is-valid-account',
          'At least one account is required',
          function (value) {
            const { path, createError } = this;

            if (value === null) {
              return createError({
                path,
                message: 'At least one Account is required',
              });
            }

            return true;
          }
        )
    )
    .required(),
  carrierTiers: yup
    .array()
    .min(1, 'Carrier Tiers is required')
    .of(yup.mixed<TIER_VALUES>().required('Carrier Tiers is required'))
    .required('Carrier Tiers is required'),
  carrierExclusions: validators.carrierList,
  carrierInclusions: validators.carrierList,
  analysisType: yup
    .mixed<ValueOf<typeof ANALYSIS_TYPES>>()
    .oneOf(Object.values(ANALYSIS_TYPES))
    .required('Analysis Type is required'),
  markupType: yup.mixed().when('rateType', {
    is: RATE_TYPES.CARRIER,
    then: yup
      .mixed<ValueOf<typeof MARKUP_TYPES>>()
      .oneOf(Object.values(MARKUP_TYPES))
      .required('Markup Type is required'),
  }),
  markupPercentType: yup.mixed().when('markupType', {
    is: MARKUP_TYPES.PERCENT,
    then: yup
      .mixed<ValueOf<typeof MARKUP_PERCENT_TYPES>>()
      .oneOf(Object.values(MARKUP_PERCENT_TYPES))
      .required('Markup Percentage Type is required'),
  }),
  markupAmount: yup.mixed().when('rateType', {
    is: RATE_TYPES.CARRIER,
    then: yup.lazy((value) =>
      value === ''
        ? yup.string().required('Markup value is required')
        : yup.number().required('Markup value is required')
    ),
  }),
  notifyEmails: yup
    .array(
      yup
        .object({
          value: validators.blugraceEmail.required(),
        })
        .required()
    )
    .required(),
  impactScacRestrictions: validators.carrierList,
  originZipExclusions: validators.zipCode,
  destinationZipExclusions: validators.zipCode,
  originStateExclusions: yup.array().of(validators.state),
  destinationStateExclusions: yup.array().of(validators.state),
  includeP44Dynamic: yup.boolean(),
  includeP44Volume: yup.boolean(),
};
