import { instance } from './provider';
import { handleResponse, handleError, CONFIG } from '../utilities';

const BASE_URL = CONFIG.jarvisApiUrl;

const endpoints = {
  upload: {
    rateRequest: `${BASE_URL}/upload/rate-request`,
  },
  dataUploadTemplate: `${BASE_URL}/data-upload-template.xlsx`,
};

export const jarvisApi = {
  uploadRateRequest: async (formData: FormData) => {
    return instance
      .post(endpoints.upload.rateRequest, formData)
      .then(handleResponse)
      .catch(handleError);
  },
  fetchDataUploadTemplate: async () => {
    return instance
      .get(endpoints.dataUploadTemplate, { responseType: 'blob' })
      .then((response) => {
        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'data-upload-template.xlsx'); // this is the name with which the file will be downloaded
        link.click();
      })
      .catch(handleError);
  },
};
