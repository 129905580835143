import { BlueShipUser } from '../../auth';

export const createFileName = (
  userName: BlueShipUser['userName'],
  accountNumber?: string,
  userFileName?: string
) => {
  const baseFileName = `RatingUI-${userName}.xlsx`;
  return userFileName
    ? `${userFileName.trim()}-${
        accountNumber ? accountNumber + '-' : ''
      }${baseFileName}`
    : baseFileName;
};
