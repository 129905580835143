import { CarrierListField, CarrierListFieldProps } from './CarrierListField';

type ImpactScacRestrictionsFieldProps<TFormFields> =
  CarrierListFieldProps<TFormFields>;

export const ImpactScacRestrictionsField = <
  TFormFields extends Record<string, unknown>
>({
  control,
  error,
  hidden,
  label,
  name,
}: ImpactScacRestrictionsFieldProps<TFormFields>) => {
  return (
    <CarrierListField<TFormFields>
      control={control}
      error={error}
      hidden={hidden}
      label={label ?? 'Impact SCAC Restrictions'}
      name={name}
    />
  );
};
