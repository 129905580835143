import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Router } from './router';

export default function App() {
  const theme = createTheme({
    components: {
      MuiTextField: {
        defaultProps: {
          sx: { mt: 2, mb: 0 },
          variant: 'outlined',
        },
      },
      MuiAutocomplete: {
        defaultProps: {
          sx: { m: 0 },
        },
      },
      MuiSelect: {
        defaultProps: {
          variant: 'outlined',
        },
      },
      MuiButton: {
        defaultProps: {
          variant: 'contained',
        },
      },
    },
    palette: {
      background: {
        default: '#e5e5e5',
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router />
    </ThemeProvider>
  );
}
