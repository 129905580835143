import { createContext, useContext } from 'react';
import { BlueShipUser } from './AuthProvider';

type AuthContextType = {
  isAuthenticated: boolean;
  isBGEmployee: boolean;
  isValidUserType: boolean;
  user: BlueShipUser | null;
  userHasOperation: (userName: string) => boolean;
  actions: {
    login: () => void;
    logout: () => void;
  };
  signinCallback: () => void;
};

export const AuthContext = createContext<AuthContextType>(
  {} as AuthContextType
);

export const useAuth = () => useContext(AuthContext);
