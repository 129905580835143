import { PullDataFormFields } from './PullDataForm';
import { COLUMN_NAMES } from './columnNames';
import { WorkbookColumnBuilders } from '../../@types';

export const pullDataRequestColumnBuilders: WorkbookColumnBuilders<PullDataFormFields> =
  {
    [COLUMN_NAMES.ACCOUNT_NAME]: (formFields) => {
      const value = formFields.accounts.map((v) => v?.name).join(',');
      return value;
    },
    [COLUMN_NAMES.ACCOUNT_NUMBER]: (formFields) => {
      const value = formFields.accounts.map((v) => v?.accountNumber).join(',');
      return value;
    },
    [COLUMN_NAMES.EMAIL_RECIPIENTS]: (formFields) => {
      const value = formFields.notifyEmails
        .map((v: { value: string }) => v.value)
        .join(',');
      return value;
    },
    [COLUMN_NAMES.DATE_RANGE.FROM_DATE]: (formFields) => {
      const value = formFields.dateRange?.startDate;
      return value;
    },
    [COLUMN_NAMES.DATE_RANGE.TO_DATE]: (formFields) => {
      const value = formFields.dateRange?.endDate;
      return value;
    },
    [COLUMN_NAMES.AUTO_RATE_SHIPMENTS]: (_) => {
      return 'False';
    },
    [COLUMN_NAMES.SCAC_LIST]: (formFields) => {
      const value = formFields.scacList?.map((v) => v?.value).join(',');
      return value;
    },
  };
