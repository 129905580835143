export const FORM_TITLES = {
  AP_TESTING: 'AP Testing Request',
  BATCH_REQUEST: 'Batch Request',
  CARRIER_RATE: 'Carrier Rate Request',
  GEO_MILEAGE: 'Geo Mileage Request',
  RATING_ANALYSIS: 'Upload & Analysis Request',
  PULL_DATA_REQUEST: 'Pull Data Request',
  PULL_DATA_ANALYSIS_REQUEST: 'Pull Data & Analysis Request',
  RETRIEVAL: 'Retrieval Request',
} as const;
