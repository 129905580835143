import { Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { Page } from '../components';
import { CONFIG } from '../utilities';

export const AccessDenied = () => <Page render={() => <Content />} />;
const Content = () => {
  return (
    <Box sx={{ width: '100%' }}>
      <Stack alignItems="center">
        <ErrorIcon
          sx={{
            fontSize: '80px',
            color: 'error.dark',
          }}
          fontSize="large"
        />
        <Typography variant="h5">
          You are not authorized to access this resource.
        </Typography>
        <Box
          component="img"
          sx={{ mt: 4, mb: 2 }}
          alt="Access Denied"
          src={`${CONFIG.jarvisApiUrl}/nedry-dennis.gif`}
        />
      </Stack>
    </Box>
  );
};
