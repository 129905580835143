import * as yup from 'yup';
import { useForm, SubmitHandler } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  FileNameField,
  PullDataDateRangeField,
  CarrierListField,
  NotifyEmailListField,
  EnterpriseSearchField,
} from '../fields';
import { SubmissionButton } from '../../components';
import { FORM_TITLES } from '../constants';
import { validators } from '../utilities';
import { useAuth } from '../../auth';
import { pullDataRequestColumnBuilders } from './workbookColumnBuilders';
import { CONTAINERS } from '../../../../shared-jarvis';
import {
  withUploadToBlobStorage,
  WithUploadToBlobStorageProps,
} from '../withUploadToBlobStorage';

const schema = yup.object({
  fileName: yup.string().required('Results file name is required'),
  dateRange: validators.dateRange,
  accounts: yup
    .array()
    .of(
      yup
        .object({
          name: yup.string().required(),
          enterpriseID: yup.number().required(),
          accountNumber: yup.string().required(),
        })
        .nullable() // work-around to set null as initial value for @mui
        .test(
          'is-valid-account',
          'At least one account is required',
          function (value) {
            const { path, createError } = this;

            if (value === null) {
              return createError({
                path,
                message: 'At least one Account is required',
              });
            }

            return true;
          }
        )
    )
    .required(),
  scacList: validators.carrierList,
  notifyEmails: yup
    .array(
      yup
        .object({
          value: validators.blugraceEmail.required(),
        })
        .required()
    )
    .required(),
});

export type PullDataFormFields = yup.InferType<typeof schema>;

const Form = ({
  handleFormSubmit,
  submitting,
}: WithUploadToBlobStorageProps<PullDataFormFields>) => {
  const auth = useAuth();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<PullDataFormFields>({
    resolver: yupResolver(schema),
    defaultValues: {
      scacList: [],
      accounts: [null],
      notifyEmails: [{ value: auth.user?.email }],
      dateRange: {
        startDate: null,
        endDate: null,
      },
    },
    shouldUnregister: true,
  });

  const onSubmit: SubmitHandler<PullDataFormFields> = async (formData) => {
    handleFormSubmit(
      formData,
      CONTAINERS.RATEQUALITY_SHIPMENTS_RETRIEVAL,
      pullDataRequestColumnBuilders
    );
  };

  return (
    <>
      <Typography
        align="center"
        component="h1"
        variant="h4"
        sx={{ mb: 3, mt: 2 }}
      >
        {FORM_TITLES.PULL_DATA_REQUEST}
      </Typography>

      <form
        style={{ marginTop: '1rem', marginBottom: 0 }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <FileNameField<PullDataFormFields>
          control={control}
          name="fileName"
          error={errors.fileName}
        />

        <PullDataDateRangeField<PullDataFormFields>
          control={control}
          name="dateRange"
          error={errors.dateRange}
        />

        <EnterpriseSearchField<PullDataFormFields>
          control={control}
          error={errors.accounts as any}
          helperText="Search by Account Name or Number"
          label="Account"
          name="accounts"
          user={auth.user!}
        />

        <CarrierListField<PullDataFormFields>
          control={control}
          error={errors.scacList}
          name="scacList"
          label="Select Carriers"
          helperText="Select carriers to be included in your data. If none are selected, all carriers will be included."
        />

        <NotifyEmailListField<PullDataFormFields>
          control={control}
          error={errors.notifyEmails}
          name="notifyEmails"
          hidden={!auth.isBGEmployee}
        />

        <SubmissionButton
          loading={submitting}
          buttonProps={{
            sx: { mt: 3, p: 2 },
            fullWidth: true,
            type: 'submit',
          }}
        />
      </form>
    </>
  );
};

export const PullDataForm = withUploadToBlobStorage(Form);
