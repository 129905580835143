import { Control, Path, FieldError } from 'react-hook-form';

import { AutocompleteField } from '../../components';
import { CARRIERS } from '../constants';

const carrierOptions = CARRIERS.map((carrier) => ({
  label: `${carrier.scac} - ${carrier.name} - ${carrier.tier}`,
  carrier,
  value: carrier.scac,
}));

export type CarrierOptionType = typeof carrierOptions;

export type CarrierListFieldProps<TFormValues> = {
  control: Control<TFormValues>;
  error?: FieldError | FieldError[];
  filterOptions?: (options: typeof carrierOptions) => typeof carrierOptions;
  helperText?: string;
  hidden?: boolean;
  label?: string;
  name: Path<TFormValues>;
};

export const CarrierListField = <TFormValues extends Record<string, unknown>>({
  control,
  error,
  filterOptions,
  helperText,
  hidden,
  label,
  name,
}: CarrierListFieldProps<TFormValues>) => (
  <AutocompleteField<TFormValues, typeof carrierOptions[number]>
    control={control}
    error={error}
    getOptionLabel={(option) => {
      if (!option) return '';
      return option.label;
    }}
    groupBy={(option) => option.carrier.tier}
    helperText={helperText}
    hidden={hidden}
    isOptionEqualToValue={(option, value) => {
      return value.value === option.value;
    }}
    label={label ?? 'Select Carriers'}
    multiple
    name={name}
    options={filterOptions ? filterOptions(carrierOptions) : carrierOptions}
  />
);
