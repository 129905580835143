import { instance } from './provider';
import { handleResponse, handleError, CONFIG } from '../utilities';

const BASE_URL = CONFIG.shipmentApiUrl;

const endpoints = {
  getCurrentUser: `${BASE_URL}/user/current`,
  enterpriseSearch: `${BASE_URL}/enterprise/search`,
  childEnterpriseSearch: `${BASE_URL}/enterprise/{enterpriseId}/children`,
};

export type CurrentUser = {
  accountNumber: string;
  branding: null;
  email: string;
  enterpriseID: number;
  firstName: string;
  lastName: string;
  operations: string[];
  userID: number;
  userName: string;
  userType: string;
};

export type Enterprise = {
  accountNumber: string;
  enterpriseID: number;
  name: string;
};

export const shipmentApi = {
  enterpriseSearch: (searchString: string): Promise<Enterprise[]> => {
    return instance
      .get<Enterprise[]>(`${endpoints.enterpriseSearch}`, {
        params: { searchString },
      })
      .then(handleResponse)
      .catch(handleError);
  },
  getChildEnterpriseOptions: (
    enterpriseID: number
  ): Promise<
    {
      accountNumber: string;
      enterpriseID: number;
      name: string;
    }[]
  > => {
    const endpoint = endpoints.childEnterpriseSearch.replace(
      '{enterpriseId}',
      enterpriseID.toString()
    );
    return instance
      .get<Enterprise[]>(endpoint)
      .then(handleResponse)
      .catch(handleError);
  },
  getCurrentUser: (userName: string): Promise<CurrentUser> => {
    const headers = { UserName: userName };
    return instance
      .get<CurrentUser>(`${endpoints.getCurrentUser}`, { headers })
      .then(handleResponse)
      .catch(handleError);
  },
};
