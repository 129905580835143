import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import { FieldError, Controller, Control, Path } from 'react-hook-form';
import FileUpload from 'react-material-file-upload';

type FileUploadError = { file?: FieldError | undefined };

type ExcelUploadProps<ControlFields> = {
  control: Control<ControlFields>;
  error?: FieldError | FieldError[] | FileUploadError[] | undefined;
  name: Path<ControlFields>;
  defaultValue?: any;
  label: string;
  helperText?: string;
  hidden?: boolean;
};

export const ExcelUploadField = <T extends object>({
  name,
  error,
  control,
  label,
  hidden,
  helperText = ' ',
}: ExcelUploadProps<T>) => {
  return hidden ? null : (
    <FormControl fullWidth error={Boolean(error)}>
      <Typography
        variant="body2"
        sx={{
          mt: 1,
          color: Boolean(error) ? 'error.main' : 'rgba(0, 0, 0, 0.6)',
        }}
      >
        {label}
      </Typography>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <FileUpload
            value={value as File[]}
            title="Click upload, or drag XLSX here to upload file"
            buttonText="Upload XLSX"
            onChange={onChange}
          />
        )}
      />
      {Array.isArray(error) && error.length > 0 ? (
        error.map((e, idx) => (
          <FormHelperText key={idx}>
            {(e as FieldError)?.message
              ? (e as FieldError)?.message
              : (e as FileUploadError)?.file?.message ?? null}
          </FormHelperText>
        ))
      ) : (
        <FormHelperText>
          {(error && !Array.isArray(error) && error.message) ?? helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};
