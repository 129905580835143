import { ContainerType, UploadFormDataFields } from '../../../../shared-jarvis';
import { jarvisApi } from '../../api';

export const submitToBlobStorage = async (
  workbooks: { file: Blob; fileName: string }[],
  targetContainer: ContainerType
) => {
  const formData = new FormData();

  workbooks.forEach((wb) => {
    formData.append(UploadFormDataFields.TARGET_CONTAINER, targetContainer);
    formData.append(UploadFormDataFields.FILE_NAMES, wb.fileName);
    formData.append(UploadFormDataFields.FILES, wb.file);
  });

  try {
    return await jarvisApi.uploadRateRequest(formData);
  } catch (e) {
    console.error(e);
    return e;
  }
};
