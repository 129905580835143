import { orderBy } from 'lodash';
import { TIERS } from './tiers';

type KeyFromValue<T, K> = K extends keyof T[keyof T] ? T[keyof T][K] : never;

export type SCAC =
  | KeyFromValue<typeof TIER_1_CARRIERS, 'scac'>
  | KeyFromValue<typeof TIER_2_CARRIERS, 'scac'>
  | KeyFromValue<typeof TIER_3_CARRIERS, 'scac'>;

export type CARRIER_NAME =
  | KeyFromValue<typeof TIER_1_CARRIERS, 'name'>
  | KeyFromValue<typeof TIER_2_CARRIERS, 'name'>
  | KeyFromValue<typeof TIER_3_CARRIERS, 'name'>;

export type CARRIER = {
  scac: SCAC;
  name: CARRIER_NAME;
  tier: TIERS;
};

const TIER_1_CARRIERS = {
  ABF_FREIGHT: { scac: 'ABFS', name: 'ABF Freight' },
  AVERITT: { scac: 'AVRT', name: 'Averitt ' },
  DAYTON_FREIGHT: { scac: 'DAFG', name: 'Dayton Freight' },
  DAYLIGHT_TRANSPORTATION: { scac: 'DYLT', name: 'Daylight Transportation' },
  FROZEN_FOOD_EXPRESS: { scac: 'FRZF', name: 'Frozen Food Express' },
  FEDEX_PRIORITY: { scac: 'FXFE', name: 'Fedex Priority' },
  FEDEX_ECONOMY: { scac: 'FXNL', name: 'Fedex Economy' },
  MOUNTAIN_VALLEY: { scac: 'MTVL', name: 'Mountain Valley ' },
  OAK_HARBOR: { scac: 'OAKH', name: 'Oak Harbor' },
  OLD_DOMINION: { scac: 'ODFL', name: 'Old Dominion' },
  PITT_OHIO: { scac: 'PITD', name: 'Pitt Ohio' },
  A_DUIE_PYLE: { scac: 'PYLE', name: 'A Duie Pyle' },
  R_AND_L: { scac: 'RLCA', name: 'R and L' },
  R_AND_L_CARRIERS: { scac: 'RNLO', name: 'R and L Carriers' },
  SOUTHEASTERN_FREIGHT: { scac: 'SEFL', name: 'Southeastern Freight' },
} as const;

const TIER_2_CARRIERS = {
  AAA_COOPER: { scac: 'AACT', name: 'AAA Cooper' },
  XPO: { scac: 'CNWY', name: 'XPO' },
  DAY_ROSS_INC: { scac: 'DAYR', name: 'Day & Ross Inc.' },
  DOHRN: { scac: 'DHRN', name: 'Dohrn' },
  DEPENDABLE_HIGHWAY: { scac: 'DPHE', name: 'Dependable Highway' },
  ERB_TRANSPORT: { scac: 'ERBL', name: 'ERB TRANSPORT' },
  ESTES: { scac: 'EXLA', name: 'Estes' },
  FAST_WAY_FREIGHT: { scac: 'FAWY', name: 'Fast Way Freight' },
  GOJIT: { scac: 'GOJI', name: 'GOJIT' },
  HEART_TRANSPORTATION: { scac: 'HEAR', name: 'Heart Transportation' },
  HOLLAND: { scac: 'HMES', name: 'Holland' },
  KINDERSLEY_TRANSPORT_CANADA: {
    scac: 'KIDY',
    name: 'KINDERSLEY TRANSPORT CANADA',
  },
  MANITOULIN_TRANSPORT_CAN: { scac: 'MANI', name: 'Manitoulin Transport CAN' },
  SIDNEY_TRANSPORTATION_SERVICES_LLC: {
    scac: 'MCAI',
    name: 'Sidney Transportation Services, LLC',
  },
  MIDWEST_MOTOR: { scac: 'MIDW', name: 'Midwest Motor' },
  NEW_PENN_MOTOR: { scac: 'NPME', name: 'New Penn Motor' },
  TST_OVERLAND_CANADA: { scac: 'OVLD', name: 'TST OVERLAND CANADA' },
  PENINSULA_TRUCK_LINES: { scac: 'PENS', name: 'Peninsula Truck Lines' },
  YRC_TIME_CRITICAL: { scac: 'RDTC', name: 'YRC Time-Critical' },
  ROADTEX_TRANSPORTATION_CORP: {
    scac: 'RDTF',
    name: 'Roadtex Transportation Corp',
  },
  YRC: { scac: 'RDWY', name: 'YRC' },
  REDDAWAY: { scac: 'RETL', name: 'Reddaway' },
  ROSEDALE_TRANSPORT: { scac: 'RTAL', name: 'Rosedale Transport' },
  SAIA: { scac: 'SAIA', name: 'SAIA' },
  SOUTHWESTERN_MOTOR: { scac: 'SMTL', name: 'SouthWestern Motor' },
  TONYS_EXPRESS_INC: { scac: 'TONI', name: "Tony's Express Inc." },
  UPS_FREIGHT: { scac: 'UPGF', name: 'UPS Freight' },
  WARD_: { scac: 'WARD', name: 'Ward ' },
  WESTERN_LOGISTICS_INC: { scac: 'WGLS', name: 'Western Logistics inc' },
} as const;

const TIER_3_CARRIERS = {
  ABERDEEN_EXPRESS: { scac: 'ABNE', name: 'Aberdeen Express' },
  AMERICAN_CARTAGE_COMPANY: { scac: 'ACGQ', name: 'American Cartage Company' },
  ALL_SERVICE_DELIVERY: { scac: 'AERY', name: 'All Service Delivery' },
  AMA_TRANSPORTATION_800_322_3210: {
    scac: 'AMAP',
    name: 'AMA Transportation 800-322-3210',
  },
  A_TRUCK_EXPRESS_INC: { scac: 'ATKE', name: 'A TRUCK EXPRESS INC' },
  A_TRUCK_EXPRESS: { scac: 'ATKR', name: 'A TRUCK EXPRESS' },
  BROWN_TRANSFER: { scac: 'BRTC', name: 'Brown Transfer' },
  B_R_ECKELS_CALG: { scac: 'BRXV', name: 'B&R ECKELS CALG' },
  BUSHNELLS_TRUCKING: { scac: 'BWCO', name: "Bushnell's Trucking" },
  CROSS_COUNTRY_FREIGHT: { scac: 'CCYQ', name: 'Cross Country Freight ' },
  CLEAR_LANE: { scac: 'CLNI', name: 'Clear Lane' },
  THE_CUSTOM_COMPANIES: { scac: 'CTBV', name: 'The Custom Companies' },
  CENTRAL_TRANSPORT: { scac: 'CTII', name: 'Central Transport' },
  DIAMOND_LINE_DELIVERY: { scac: 'DLDS', name: 'Diamond Line Delivery' },
  DATS_TRUCKING_INC: { scac: 'DTST', name: 'DATS Trucking, Inc.' },
  DUGAN_TRUCK_LINE: { scac: 'DUBL', name: 'Dugan Truck Line' },
  EXPRESS_FREIGHT_SYSTEM: { scac: 'EPFS', name: 'Express Freight System' },
  EXPRESS_TRUCK_LINES: { scac: 'ESKQ', name: 'EXPRESS TRUCK LINES' },
  FRONTLINE: { scac: 'FCSY', name: 'Frontline' },
  FORWARD_AIR: { scac: 'FWDA', name: 'Forward Air' },
  HIGHLIGHT_MOTOR_FREIGHT_INC: {
    scac: 'HMFR',
    name: 'Highlight Motor Freight Inc',
  },
  RIST_TRANSPORT: { scac: 'HWEP', name: 'RIST Transport' },
  JAHN_TRANSFER_608_781_5377: {
    scac: 'JAHN',
    name: 'Jahn Transfer 608-781-5377',
  },
  LAND_AIR: { scac: 'LAXV', name: 'Land Air' },
  LINE_DRIVE_TRUCKING_INC: { scac: 'LDTN', name: 'Line Drive Trucking Inc' },
  MAGNUM_LTL_INC: { scac: 'MGUL', name: 'Magnum LTL Inc.' },
  MJB_FREIGHT_SYSTEMS_INC: { scac: 'MJBF', name: 'MJB Freight Systems, INC' },
  MONROE_TRANSPORTATION_SERVICES_INC: {
    scac: 'MONC',
    name: 'Monroe Transportation Services, Inc',
  },
  MORAN: { scac: 'MTJG', name: 'Moran' },
  N_M_TRANSFER: { scac: 'NMTF', name: 'N&M Transfer' },
  NORTH_PARK_TRANSPORTATION_CO: {
    scac: 'NOPK',
    name: 'North Park Transportation Co.',
  },
  NUMARK_TRANSPORTATION_INC: {
    scac: 'NUMK',
    name: 'Numark Transportation, Inc',
  },
  ROADRUNNER: { scac: 'RDFS', name: 'RoadRunner' },
  CARGO_TRANSPORTATION_SERVICES: {
    scac: 'SCTP',
    name: 'Cargo Transportation Services',
  },
  SONIQ_TRANSPORTATION: { scac: 'SNWS', name: 'Soniq Transportation' },
  STANDARD_FORWARDING: { scac: 'STDF', name: 'Standard Forwarding' },
  SUTTON_TRANSPORT_LTL: { scac: 'SUON', name: 'Sutton Transport LTL' },
  SUPERIOR_EXPRESS_LTL: { scac: 'SUXP', name: 'Superior Express LTL' },
  TAX_AIR: { scac: 'TAXA', name: 'Tax Air' },
  VOCAR_TRANSPORT: { scac: 'VCTS', name: 'Vocar Transport' },
  XGSI: { scac: 'XGSI', name: 'XGSI ' },
} as const;

export const CARRIERS: CARRIER[] = orderBy(
  [
    ...Object.values(TIER_1_CARRIERS).map(({ scac, name }) => ({
      scac,
      name,
      tier: TIERS.TIER_1,
    })),
    ...Object.values(TIER_2_CARRIERS).map(({ scac, name }) => ({
      scac,
      name,
      tier: TIERS.TIER_2,
    })),
    ...Object.values(TIER_3_CARRIERS).map(({ scac, name }) => ({
      scac,
      name,
      tier: TIERS.TIER_3,
    })),
  ],
  ['tier', 'name']
);
