const STATES = [
  {
    name: 'Alabama',
    abbrev: 'AL',
    nation: 'USA',
  },
  {
    name: 'Kentucky',
    abbrev: 'KY',
    nation: 'USA',
  },
  {
    name: 'Ohio',
    abbrev: 'OH',
    nation: 'USA',
  },
  {
    name: 'Alaska',
    abbrev: 'AK',
    nation: 'USA',
  },
  {
    name: 'Louisiana',
    abbrev: 'LA',
    nation: 'USA',
  },
  {
    name: 'Oklahoma',
    abbrev: 'OK',
    nation: 'USA',
  },
  {
    name: 'Arizona',
    abbrev: 'AZ',
    nation: 'USA',
  },
  {
    name: 'Maine',
    abbrev: 'ME',
    nation: 'USA',
  },
  {
    name: 'Oregon',
    abbrev: 'OR',
    nation: 'USA',
  },
  {
    name: 'Arkansas',
    abbrev: 'AR',
    nation: 'USA',
  },
  {
    name: 'Maryland',
    abbrev: 'MD',
    nation: 'USA',
  },
  {
    name: 'Pennsylvania',
    abbrev: 'PA',
    nation: 'USA',
  },
  // {
  //   name: "American Samoa",
  //   abbrev: "AS",
  // nation: 'USA',  },
  {
    name: 'Massachusetts',
    abbrev: 'MA',
    nation: 'USA',
  },
  // {
  //   name: "Puerto Rico",
  //   abbrev: "PR",
  // nation: 'USA',  },
  {
    name: 'California',
    abbrev: 'CA',
    nation: 'USA',
  },
  {
    name: 'Michigan',
    abbrev: 'MI',
    nation: 'USA',
  },
  {
    name: 'Rhode Island',
    abbrev: 'RI',
    nation: 'USA',
  },
  {
    name: 'Colorado',
    abbrev: 'CO',
    nation: 'USA',
  },
  {
    name: 'Minnesota',
    abbrev: 'MN',
    nation: 'USA',
  },
  {
    name: 'South Carolina',
    abbrev: 'SC',
    nation: 'USA',
  },
  {
    name: 'Connecticut',
    abbrev: 'CT',
    nation: 'USA',
  },
  {
    name: 'Mississippi',
    abbrev: 'MS',
    nation: 'USA',
  },
  {
    name: 'South Dakota',
    abbrev: 'SD',
    nation: 'USA',
  },
  {
    name: 'Delaware',
    abbrev: 'DE',
    nation: 'USA',
  },
  {
    name: 'Missouri',
    abbrev: 'MO',
    nation: 'USA',
  },
  {
    name: 'Tennessee',
    abbrev: 'TN',
    nation: 'USA',
  },
  // {
  //   name: "District of Columbia",
  //   abbrev: "DC",
  // nation: 'USA',  },
  {
    name: 'Montana',
    abbrev: 'MT',
    nation: 'USA',
  },
  {
    name: 'Texas',
    abbrev: 'TX',
    nation: 'USA',
  },
  {
    name: 'Florida',
    abbrev: 'FL',
    nation: 'USA',
  },
  {
    name: 'Nebraska',
    abbrev: 'NE',
    nation: 'USA',
  },
  // {
  //   name: "Trust Territories",
  //   abbrev: "TT",
  // nation: 'USA',  },
  {
    name: 'Georgia',
    abbrev: 'GA',
    nation: 'USA',
  },
  {
    name: 'Nevada',
    abbrev: 'NV',
    nation: 'USA',
  },
  {
    name: 'Utah',
    abbrev: 'UT',
    nation: 'USA',
  },
  // {
  //   name: "Guam",
  //   abbrev: "GU",
  // nation: 'USA',  },
  {
    name: 'New Hampshire',
    abbrev: 'NH',
    nation: 'USA',
  },
  {
    name: 'Vermont',
    abbrev: 'VT',
    nation: 'USA',
  },
  {
    name: 'Hawaii',
    abbrev: 'HI',
    nation: 'USA',
  },
  {
    name: 'New Jersey',
    abbrev: 'NJ',
    nation: 'USA',
  },
  {
    name: 'Virginia',
    abbrev: 'VA',
    nation: 'USA',
  },
  {
    name: 'Idaho',
    abbrev: 'ID',
    nation: 'USA',
  },
  {
    name: 'New Mexico',
    abbrev: 'NM',
    nation: 'USA',
  },
  // {
  //   name: "Virgin Islands",
  //   abbrev: "VI",
  // nation: 'USA',  },
  {
    name: 'Illinois',
    abbrev: 'IL',
    nation: 'USA',
  },
  {
    name: 'New York',
    abbrev: 'NY',
    nation: 'USA',
  },
  {
    name: 'Washington',
    abbrev: 'WA',
    nation: 'USA',
  },
  {
    name: 'Indiana',
    abbrev: 'IN',
    nation: 'USA',
  },
  {
    name: 'North Carolina',
    abbrev: 'NC',
    nation: 'USA',
  },
  {
    name: 'West Virginia',
    abbrev: 'WV',
    nation: 'USA',
  },
  {
    name: 'Iowa',
    abbrev: 'IA',
    nation: 'USA',
  },
  {
    name: 'North Dakota',
    abbrev: 'ND',
    nation: 'USA',
  },
  {
    name: 'Wisconsin',
    abbrev: 'WI',
    nation: 'USA',
  },
  {
    name: 'Kansas',
    abbrev: 'KS',
    nation: 'USA',
  },
  // {
  //   name: "Northern Mariana Islands",
  //   abbrev: "CM",
  // nation: 'USA',  },
  {
    name: 'Wyoming',
    abbrev: 'WY',
    nation: 'USA',
  },
] as const;

const PROVINCES = [
  {
    name: 'Alberta',
    abbrev: 'AB',
    nation: 'Canada',
  },
  {
    name: 'British Columbia',
    abbrev: 'BC',
    nation: 'Canada',
  },
  {
    name: 'Manitoba',
    abbrev: 'MB',
    nation: 'Canada',
  },
  {
    name: 'New Brunswick',
    abbrev: 'NB',
    nation: 'Canada',
  },
  {
    name: 'Newfoundland and Labrador',
    abbrev: 'NL',
    nation: 'Canada',
  },
  {
    name: 'Northwest Territories',
    abbrev: 'NT',
    nation: 'Canada',
  },
  {
    name: 'Nova Scotia',
    abbrev: 'NS',
    nation: 'Canada',
  },
  {
    name: 'Nunavut',
    abbrev: 'NU',
    nation: 'Canada',
  },
  {
    name: 'Ontario',
    abbrev: 'ON',
    nation: 'Canada',
  },
  {
    name: 'Prince Edward Island',
    abbrev: 'PE',
    nation: 'Canada',
  },
  {
    name: 'Quebec',
    abbrev: 'QC',
    nation: 'Canada',
  },
  {
    name: 'Saskatchewan',
    abbrev: 'SK',
    nation: 'Canada',
  },
  {
    name: 'Yukon',
    abbrev: 'YT',
    nation: 'Canada',
  },
];

export const STATE_OPTIONS = [
  ...[...STATES].sort((a, b) => a.name.localeCompare(b.name)),
  ...[...PROVINCES].sort((a, b) => a.name.localeCompare(b.name)),
];
