// Pulled from UNICODE standard
// @see: https://github.com/unicode-org/cldr/blob/release-26-0-1/common/supplemental/postalCodeData.xml
const ZIP_REGEX = {
  US: new RegExp(/^\d{5}(?:[ \-]\d{4})?$/),
  CA: new RegExp(
    /^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ ]?\d[ABCEGHJ-NPRSTV-Z]\d/
  ),
};

export const isValidZipCodeFormat = (zipCode: string) => {
  const isValidUs = ZIP_REGEX.US.test(zipCode);
  const isValidCa = ZIP_REGEX.CA.test(zipCode);

  return isValidUs || isValidCa;
};
