import { datadogLogs } from '@datadog/browser-logs';
import { CONFIG } from '../utilities';

datadogLogs.init({
  clientToken: CONFIG.DATADOG_CLIENT_TOKEN,
  service: CONFIG.DATADOG_SERVICE,
  forwardErrorsToLogs: true,
  sampleRate: 100,
  env: CONFIG.env,
  beforeSend: (log) => {
    // don't log in dev mode
    if (CONFIG.isDevelopment) return false;
  },
});

export const { logger } = datadogLogs;
