export const ROUTES = {
  HOME: {
    label: 'Home',
    path: '/',
    mainNav: false,
  },
  UPLOAD_ANALYZE: {
    label: 'Upload Data & Create Analysis Request',
    path: '/upload-analyze',
    mainNav: true,
  },
  PULL_DATA: {
    label: 'Pull Data Request',
    path: '/pull-data',
    mainNav: true,
  },
  PULL_DATA_ANALYZE: {
    label: 'Pull Data & Analysis Request',
    path: '/pull-data-analyze',
    mainNav: true,
  },
  SIGNIN_CALLBACK: {
    path: '/signin-callback',
    mainNav: false,
  },
} as const;
