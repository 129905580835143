import React from 'react';
import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LoginIcon from '@mui/icons-material/Login';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import LogoutIcon from '@mui/icons-material/Logout';

import { useAuth } from '../auth';
import { mainNavLinks } from '../router';

export const Header = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showAvatarMenu, setShowAvatarMenu] = React.useState<boolean>(false);
  const [showMainMenu, setShowMainMenu] = React.useState<boolean>(false);

  const auth = useAuth();
  const navigate = useNavigate();

  const handleMainMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setShowMainMenu(true);
    setAnchorEl(event.currentTarget);
  };

  const handleAvatarClick = (event: React.MouseEvent<HTMLElement>) => {
    setShowAvatarMenu(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setShowAvatarMenu(false);
    setShowMainMenu(false);
    setAnchorEl(null);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            aria-controls="menu-appbar"
            color="inherit"
            aria-label="main-menu"
            onClick={handleMainMenuClick}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            id="main-menu-appbar"
            open={showMainMenu}
            onClose={handleClose}
          >
            {mainNavLinks}
          </Menu>
          <Typography
            onClick={() => navigate('/')}
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, cursor: 'pointer' }}
          >
            Rating Portal
          </Typography>
          {auth.user ? (
            <>
              Hello {auth.user?.userName}
              <IconButton
                size="large"
                aria-label={`account of ${auth.user?.userName}`}
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleAvatarClick}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="avatar-menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={showAvatarMenu}
                onClose={handleClose}
              >
                <MenuItem onClick={auth.actions.logout}>
                  <ListItemIcon>
                    <LogoutIcon fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            </>
          ) : (
            <>
              <IconButton
                size="large"
                aria-label="login"
                aria-haspopup="false"
                onClick={auth.actions?.login}
                color="inherit"
              >
                <LoginIcon />
                <Typography sx={{ ml: '10px' }}>Login</Typography>
              </IconButton>
            </>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
};
