import { SYSTEMS } from '../constants';
import { RateRequestFormFields } from './UploadAnalyzeForm';
import { COLUMN_NAMES } from './columnNames';
import { WorkbookColumnBuilders } from '../../@types';

/**
 * - Data Type
 * - Rate Type
 * - Request Type
 * These are entirely for UX/Form purposes and should
 * not have their values sent to the J.A.R.V.I.S. system
 */

type Systems = typeof SYSTEMS[keyof typeof SYSTEMS];

export const analysisRequestColumnBuilders: WorkbookColumnBuilders<RateRequestFormFields> =
  {
    [COLUMN_NAMES.SYSTEMS_TO_RATE]: (formFields) => {
      let value: Systems[] = [SYSTEMS.BG];
      if (formFields.includeP44Dynamic || formFields.includeP44Volume) {
        value.push(SYSTEMS.P44);
      }
      return value.join(',');
    },
    [COLUMN_NAMES.ACCOUNT_NAME]: (formFields) => {
      const value = formFields.accounts.map((v) => v?.name).join(',');
      return value;
    },
    [COLUMN_NAMES.ACCOUNT_NUMBER]: (formFields) => {
      const value = formFields.accounts.map((v) => v?.accountNumber).join(',');
      return value;
    },
    [COLUMN_NAMES.CARRIER_TIERS]: (formFields) => {
      const value = formFields.carrierTiers.join(',');
      return value;
    },
    [COLUMN_NAMES.CARRIER_EXCLUSION]: (formFields) => {
      const value = formFields.carrierExclusions
        ?.map((carrier) => carrier.value)
        .join(',');
      return value;
    },
    [COLUMN_NAMES.CARRIER_INCLUSION]: (formFields) => {
      const value = formFields.carrierInclusions
        ?.map((carrier) => carrier.value)
        .join(',');
      return value;
    },
    [COLUMN_NAMES.ANALYSIS_TYPE]: (formFields) => {
      const value = formFields.analysisType;
      return value;
    },
    [COLUMN_NAMES.IMPACT_CARRIER_EXCLUSIONS]: (formFields) => {
      const value = formFields.impactScacRestrictions
        ?.map((v) => v?.value)
        .join(',');
      return value;
    },
    [COLUMN_NAMES.IMPACT_DESTINATION_POSTAL_EXCLUSIONS]: (formFields) => {
      const value = formFields.destinationZipExclusions;
      return value;
    },
    [COLUMN_NAMES.IMPACT_DESTINATION_STATE_EXCLUSIONS]: (formFields) => {
      const value = formFields.destinationStateExclusions
        ?.map((v) => v?.value)
        .join(',');
      return value;
    },
    [COLUMN_NAMES.IMPACT_ORIGIN_POSTAL_EXCLUSIONS]: (formFields) => {
      const value = formFields.originZipExclusions;
      return value;
    },
    [COLUMN_NAMES.IMPACT_ORIGIN_STATE_EXCLUSIONS]: (formFields) => {
      const value = formFields.originStateExclusions
        ?.map((v) => v?.value)
        .join(',');
      return value;
    },
    [COLUMN_NAMES.MARKUP_TYPE]: (formFields) => {
      const value = formFields.markupType;
      return value;
    },
    [COLUMN_NAMES.MARKUP_PERCENT_TYPE]: (formFields) => {
      const value = formFields.markupPercentType;
      return value;
    },
    [COLUMN_NAMES.MARKUP_AMOUNT]: (formFields) => {
      const value = formFields.markupAmount;
      return value;
    },
    [COLUMN_NAMES.NOTIFICATION_EMAIL]: (formFields) => {
      const value = formFields.notifyEmails
        .map((v: { value: string }) => v.value)
        .join(',');
      return value;
    },
    [COLUMN_NAMES.MARKUP_AMOUNT]: (formFields) => {
      const value = formFields.markupAmount;
      return value;
    },
  };
