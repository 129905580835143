import React from 'react';
import Snackbar from '@mui/material/Snackbar';

import { logger } from '../logging';
import { useExcelWorkerApi } from '../hooks';
import { Alert, UploadStatus } from '../components';
import { SubmissionSuccess } from './SubmissionSuccess';
import { createFileName, submitToBlobStorage } from './utilities';
import { useAuth } from '../auth';
import { CONTAINERS } from '../../../shared-jarvis';
import { mapFieldsToWorkbookColumns } from './utilities/mapFieldToWorkbookColumn';

type BaseFieldValues = {
  accounts: ({
    name: string;
    enterpriseID: number;
    accountNumber: string;
  } | null)[];
  fileName: string;
  notifyEmails: { value: string }[];
  shipmentsFileUpload?: File[];
};

export type WithUploadToBlobStorageProps<TFieldValues extends BaseFieldValues> =
  {
    handleFormSubmit: (
      formData: TFieldValues,
      container: typeof CONTAINERS[keyof typeof CONTAINERS],
      columnBuilders: any
    ) => Promise<void>;
    submitting: boolean;
    [x: string]: any;
  };

export const withUploadToBlobStorage = <TFieldValues,>(
  FormComponent: React.ComponentType<
    WithUploadToBlobStorageProps<TFieldValues & BaseFieldValues>
  >
) => {
  const WrappedForm = (props: Record<string, any>) => {
    const auth = useAuth();
    const excelWorkerApi = useExcelWorkerApi();
    const [submitting, setSubmitting] = React.useState(false);
    const [showSuccess, setShowSuccess] = React.useState(false);
    const [showError, setShowError] = React.useState(false);
    const [statusMessage, setStatusMessage] = React.useState<{
      main: string;
      submessage: string;
    }>({ main: '', submessage: '' });

    // Submission side-effects
    React.useEffect(() => {
      if (submitting && (showSuccess || showError)) {
        setSubmitting(false);
        setStatusMessage({ main: '', submessage: '' });
      }
    }, [submitting, showSuccess, showError]);

    const handleCloseError = (
      _: React.SyntheticEvent | Event,
      reason?: string
    ) => {
      if (reason === 'clickaway') {
        return;
      }

      setShowError(false);
    };

    const handleFormSubmit: WithUploadToBlobStorageProps<
      TFieldValues & BaseFieldValues
    >['handleFormSubmit'] = async (formData, container, columnBuilders) => {
      try {
        const startTime = Date.now();

        setSubmitting(true);

        // Only send results to the user submitting the request if they aren't a BG Employee type.
        if (!auth.isBGEmployee) {
          formData.notifyEmails = [{ value: auth.user!.email }];
        }

        const hasShipmentData = Array.isArray(formData.shipmentsFileUpload);

        // Only showing status messages for shipment files over 0.5MB to reduce UI flicker
        // Showing submessage for files over 3MB as these could take a long time to upload
        const fileSize = hasShipmentData
          ? formData.shipmentsFileUpload?.[0]?.size!
          : 0;
        const showStatusMessages = fileSize > 500000; // 0.5MB
        const isLargeFile = fileSize > 3000000; // 3MB
        const isExtreme = isLargeFile && formData.accounts.length > 1;
        const submessage = isExtreme
          ? 'This is a very large request. Please do not close this window, and allow some time for this request to complete...'
          : isLargeFile
          ? "Looks like you're uploading a large file. This usually takes a few minutes..."
          : '';

        hasShipmentData &&
          showStatusMessages &&
          setStatusMessage({
            main: 'Reading your shipment data...',
            submessage,
          });

        const shipmentSheet = await excelWorkerApi.getSheetFromUpload(
          formData.shipmentsFileUpload
        );

        showStatusMessages &&
          setStatusMessage({
            main: 'Creating your query...',
            submessage,
          });

        // Create a workbook upload for each account selected
        const workbooks = await Promise.all(
          formData.accounts.map(async (account: any) => {
            const configSheetData = mapFieldsToWorkbookColumns(
              { ...formData, accounts: [account] },
              columnBuilders
            );
            return {
              file: await excelWorkerApi.createWorkbook(
                configSheetData,
                shipmentSheet
              ),
              fileName: createFileName(
                auth.user?.userName!,
                account?.accountNumber,
                formData.fileName
              ),
            };
          })
        );

        showStatusMessages &&
          setStatusMessage({
            main: 'Uploading your query...',
            submessage,
          });

        const response = await submitToBlobStorage(workbooks, container);

        const endTime = Date.now();

        const elapsedTime = endTime - startTime;

        if (response.status === 200) {
          logger.info(`Submission Success - ${window.location.pathname}`, {
            ...response,
            elapsedTime,
          });
          setShowSuccess(true);
        } else {
          logger.error(`Submission Failed - ${window.location.pathname}`, {
            ...response,
            elapsedTime,
          });
          setShowError(true);
        }
      } catch (e: any) {
        logger.error(`Submission Error - ${window.location.pathname}`, {
          error: { message: e.message, stack: e.stack },
        });
      }
    };

    return showSuccess ? (
      <SubmissionSuccess />
    ) : (
      <>
        <FormComponent
          {...props}
          handleFormSubmit={handleFormSubmit}
          submitting={submitting}
        />
        <Snackbar
          autoHideDuration={3000}
          open={showError}
          onClose={handleCloseError}
        >
          <Alert
            onClose={handleCloseError}
            severity="error"
            sx={{ width: '100%' }}
          >
            Upload Failed!
          </Alert>
        </Snackbar>
        <UploadStatus statusMessage={statusMessage} />
      </>
    );
  };

  return WrappedForm;
};
