import { Control, Path, FieldError } from 'react-hook-form';

import { DropdownField } from '../../components';
import { REQUEST_TYPES } from '../constants';
import { constToOption } from '../utilities';

const requestTypeOptions = Object.values(REQUEST_TYPES).map((v) =>
  constToOption(v)
);

type RequestTypesFieldProps<TFormValues> = {
  control: Control<TFormValues>;
  error?: FieldError;
  helperText?: string;
  label?: string;
  name: Path<TFormValues>;
};

export const RequestTypesField = <TFormValues extends Record<string, unknown>>({
  control,
  error,
  helperText,
  label,
  name,
}: RequestTypesFieldProps<TFormValues>) => (
  <DropdownField<TFormValues>
    control={control}
    error={error}
    helperText={helperText}
    label={label ?? 'Request Types'}
    name={name}
    options={requestTypeOptions}
  />
);
