import { logger } from './logging';
import {
  ErrorBoundary,
  ErrorBoundaryPropsWithComponent,
} from 'react-error-boundary';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { GlobalErrorFallback } from './pages';
import { AuthProvider } from './auth';

const container = document.getElementById('root');

const errorHandler: ErrorBoundaryPropsWithComponent['onError'] = (
  error,
  info
) => {
  logger.error('Uncaught Error', {
    error: { message: error.message, stack: error.stack },
    info,
  });
};

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary
      FallbackComponent={GlobalErrorFallback}
      onError={errorHandler}
    >
      <AuthProvider>
        <App />
      </AuthProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  container
);
