import React from 'react';
import { FieldError, Control, Path } from 'react-hook-form';
import { shipmentApi } from '../../api';
import { BlueShipUser, useAuth } from '../../auth';
import { AsyncTypeAhead } from '../../components/fields/AsyncTypeAhead';
import { AutocompleteField } from '../../components/fields/AutocompleteField';

type EnterpriseSearchFieldProps<TFormValues> = {
  control: Control<TFormValues>;
  error?: FieldError | FieldError[];
  helperText: string;
  label: string;
  multiple?: boolean;
  name: Path<TFormValues>;
  user: BlueShipUser;
};

type ChildEnterprise = Awaited<
  ReturnType<typeof shipmentApi.getChildEnterpriseOptions>
>[number];

export const EnterpriseSearchField = <
  TFormValues extends Record<string, unknown>
>({
  control,
  error,
  helperText,
  label,
  multiple = false,
  name,
  user,
}: EnterpriseSearchFieldProps<TFormValues>) => {
  const { isBGEmployee } = useAuth();

  const [childEnterpriseOptions, setChildEnterpriseOptions] = React.useState<
    ChildEnterprise[]
  >([]);

  React.useEffect(() => {
    if (!isBGEmployee) {
      (async () => {
        const options = await shipmentApi.getChildEnterpriseOptions(
          user.enterpriseID
        );
        setChildEnterpriseOptions(options);
      })();
    }
  }, [user.userType]);

  const handleChildEnterprisePaste = React.useCallback(
    (e, onChange, currentValue) => {
      const pastedData = e.clipboardData.getData('Text');
      const accountNumbers = pastedData
        .split(',')
        .map((an: string) => an.trim());

      const matchedAccounts: ChildEnterprise[] = accountNumbers.reduce(
        (foundAccounts: ChildEnterprise[], accountNumber: string) => {
          const match = childEnterpriseOptions.find(
            (opt) => opt.accountNumber === accountNumber
          );
          if (match) {
            foundAccounts.push(match);
          }
          return foundAccounts;
        },
        [] as ChildEnterprise[]
      );

      if (!matchedAccounts.length) return;

      const newValue = currentValue
        .filter((v: any) => v !== null)
        .concat(multiple ? matchedAccounts : matchedAccounts[0]);

      if (newValue.length > 0) onChange(newValue);
    },
    [multiple, childEnterpriseOptions]
  );

  return isBGEmployee ? (
    <AsyncTypeAhead<
      TFormValues,
      Awaited<ReturnType<typeof shipmentApi.enterpriseSearch>>[number]
    >
      multiple={multiple}
      control={control}
      searchEndpoint={shipmentApi.enterpriseSearch}
      optionIdentityKey="enterpriseID"
      getOptionLabel={(option) => {
        if (!option) return '';
        return `${option.accountNumber}: ${option.name}`;
      }}
      isOptionEqualToValue={(option, value) => {
        // Known @MUI issue: Waring: The value provided to Autocomplete is invalid. None of the options match.
        // @see: https://github.com/mui/material-ui/issues/29727
        return value.enterpriseID === option.enterpriseID;
      }}
      label={label}
      name={name}
      helperText={helperText}
      error={error}
    />
  ) : (
    <AutocompleteField
      control={control}
      error={error}
      getOptionLabel={(option) => {
        if (!option) return '';
        return `${option.accountNumber}: ${option.name}`;
      }}
      helperText={helperText}
      isOptionEqualToValue={(option, value) => {
        return value?.enterpriseID === option.enterpriseID;
      }}
      label={label}
      multiple={multiple}
      name={name}
      onPaste={handleChildEnterprisePaste}
      options={childEnterpriseOptions}
    />
  );
};
