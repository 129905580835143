import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { RequireAuth } from '../auth';
import {
  Home,
  NotFound,
  PullData,
  PullDataAnalyze,
  SigninCallback,
  UploadAnalyze,
} from '../pages';
import { ROUTES } from './constants';

export const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={ROUTES.SIGNIN_CALLBACK.path}
          element={<SigninCallback />}
        />
        {/* AUTH ROUTES */}
        <Route element={<RequireAuth />}>
          <Route path={ROUTES.HOME.path} element={<Home />} />
          <Route
            path={ROUTES.UPLOAD_ANALYZE.path}
            element={<UploadAnalyze />}
          />
          <Route path={ROUTES.PULL_DATA.path} element={<PullData />} />
          <Route
            path={ROUTES.PULL_DATA_ANALYZE.path}
            element={<PullDataAnalyze />}
          />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};
