import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useFieldArray, FieldError } from 'react-hook-form';

type ArrayFieldProps = {
  control: any;
  disableByIndex?: number[];
  error?: { value?: FieldError }[];
  hidden?: boolean;
  label: string;
  name: string;
  renderItem: ({
    field,
    index,
  }: {
    field: Record<'id', string>;
    index: number;
  }) => {
    fieldControl: React.ReactNode;
    canEdit: boolean;
    canRemove: boolean;
  };
};

export const ArrayField = ({
  control,
  error,
  hidden,
  label,
  name,
  renderItem,
}: ArrayFieldProps) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  return hidden ? null : (
    <FormControl sx={{ mt: 1 }} fullWidth>
      <Typography
        variant="body2"
        sx={{
          mt: 1,
          color: Boolean(error) ? 'error.main' : 'rgba(0, 0, 0, 0.6)',
        }}
      >
        {label}
      </Typography>
      <Grid container>
        {fields.map((field, index) => {
          const {
            fieldControl,
            canRemove,
            canEdit = true,
          } = renderItem({ field, index });
          return (
            <React.Fragment key={`${name}_${index}`}>
              <Grid item xs={8}>
                {fieldControl}
              </Grid>
              <Grid item xs={2}>
                {fields.length !== 1 && canRemove && canEdit && (
                  <Box
                    height="100%"
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                  >
                    <RemoveCircleIcon
                      color="error"
                      sx={{ margin: 'auto' }}
                      onClick={() => remove(index)}
                    />
                  </Box>
                )}
              </Grid>
              {index === fields.length - 1 && canEdit && (
                <Grid item xs={2}>
                  <Box
                    height="100%"
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                  >
                    <AddCircleIcon
                      color="success"
                      sx={{ margin: 'auto' }}
                      onClick={() => append({ value: undefined })}
                    />
                  </Box>
                </Grid>
              )}
            </React.Fragment>
          );
        })}
      </Grid>
    </FormControl>
  );
};
