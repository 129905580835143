import React from 'react';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Popover from '@mui/material/Popover';
import HelpIcon from '@mui/icons-material/Help';
import { FieldError, Controller, Control, Path } from 'react-hook-form';

type DropdwonFieldProps<ControlFields> = {
  control: Control<ControlFields>;
  error?: FieldError;
  label: string;
  helperText?: string;
  options: { value: string; label?: string; helpText?: string }[];
  name: Path<ControlFields>;
  hidden?: boolean;
  disabled?: boolean;
  defaultValue?: any;
};

export const DropdownField = <T extends object>({
  label,
  helperText = ' ',
  name,
  options,
  error,
  control,
  hidden,
  disabled,
  defaultValue = '',
}: DropdwonFieldProps<T>) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleHelpTextOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleHelpTextClose = () => {
    setAnchorEl(null);
  };

  return hidden ? null : (
    <FormControl sx={{ mt: 2 }} fullWidth error={Boolean(error)}>
      <InputLabel>{label}</InputLabel>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <Select
            defaultValue={defaultValue}
            disabled={disabled}
            label={label}
            inputRef={ref}
            value={(value as string) ?? defaultValue}
            onChange={onChange}
            onBlur={onBlur}
            renderValue={(value) => {
              const option = options.find((opt) => opt.value === value);
              return option?.label ?? value;
            }}
          >
            {options.map((option, idx) => {
              const isPopoverOpen = anchorEl?.id === `${name}option_${idx}`;
              return (
                <MenuItem key={idx} value={option.value}>
                  {option.label ?? option.value}
                  {option.helpText && (
                    <Box
                      id={`${name}option_${idx}`}
                      aria-owns={
                        isPopoverOpen
                          ? 'dropdown-item-mouse-over-popover'
                          : undefined
                      }
                      aria-haspopup="true"
                      onMouseEnter={handleHelpTextOpen}
                      onMouseLeave={handleHelpTextClose}
                    >
                      <HelpIcon sx={{ ml: 2 }} />
                      <Popover
                        id="dropdown-item-mouse-over-popover"
                        open={isPopoverOpen}
                        anchorEl={isPopoverOpen ? anchorEl : null}
                        onClose={handleHelpTextClose}
                        sx={{
                          pointerEvents: 'none',
                        }}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        PaperProps={{
                          sx: {
                            ml: 1,
                            p: 1,
                            maxWidth: '400px',
                          },
                        }}
                      >
                        {option.helpText}
                      </Popover>
                    </Box>
                  )}
                </MenuItem>
              );
            })}
          </Select>
        )}
      />
      <FormHelperText>{error?.message ?? helperText}</FormHelperText>
    </FormControl>
  );
};
