import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';

type SubmissionButtonProps = {
  loading: boolean;
  buttonProps?: ButtonProps;
  adornmentProps?: CircularProgressProps;
};

const SpinnerAdornment = styled(CircularProgress)`
  color: white;
`;

export const SubmissionButton = ({
  buttonProps,
  adornmentProps,
  loading,
}: SubmissionButtonProps) => (
  <Button {...buttonProps} disabled={loading}>
    {loading ? 'Submitting...' : 'Submit'}
    {loading && (
      <InputAdornment position="end">
        <SpinnerAdornment size={20} {...adornmentProps} />
      </InputAdornment>
    )}
  </Button>
);
