import { CONFIG } from '../../utilities';

export const SYSTEMS = {
  BG: 'BG',
  // MG: "MG",
  P44: 'P44',
} as const;

export const REQUEST_TYPES = {
  BLANKETS: 'Blankets',
  ASP: 'ASP',
} as const;

export const RATE_TYPES = {
  CUSTOMER: 'Customer',
  CARRIER: 'Carrier',
} as const;

export const ANALYSIS_TYPES = {
  SAME_CARRIER: 'Same Carrier',
  LEAST_COST_CARRIER: 'Least Cost Carrier',
  SAME_OR_BETTER_TRANSIT: 'Same or Better Transit',
  FASTEST_TRANSIT: 'Fastest Transit',
  BENCHMARK: 'Benchmark',
  IMPACT_ANALYSIS: 'Impact Analysis',
  // TODO: Coming soon...
  // COVERAGE_ANALYSIS: "Coverage Analysis",
} as const;

export const ANALYSIS_TYPE_DETAILS = {
  [ANALYSIS_TYPES.SAME_CARRIER]:
    'Match the SCAC codes in the Import file and return the current rates in the output',
  [ANALYSIS_TYPES.LEAST_COST_CARRIER]:
    'Use the carrier selection to provide the cheapest cost in the rates provided',
  [ANALYSIS_TYPES.SAME_OR_BETTER_TRANSIT]:
    'Match the Service days (Transit Time) on the import file and return the cheapest carrier with the same or better transit time',
  [ANALYSIS_TYPES.FASTEST_TRANSIT]:
    'Match the Service days (Transit Time) on the import file and return the cheapest carrier with the fastest transit time',
  [ANALYSIS_TYPES.BENCHMARK]: 'Compare current rates to the benchmark contract',
  [ANALYSIS_TYPES.IMPACT_ANALYSIS]:
    'Analyze the impact of removing carriers, zips or states from the current customer profile',
  // TODO: Coming soon...
  // [ANALYSIS_TYPES.COVERAGE_ANALYSIS]:
  //   "Returns whether each lane is direct or indirect",
} as const;

export const MARKUP_TYPES = {
  FLAT: 'Flat',
  PERCENT: '%',
  CURRENT: 'Current',
  // SEGMENT: "Segment",
} as const;

export const MARKUP_TYPES_LABELS = {
  [MARKUP_TYPES.FLAT]: 'Flat ($)',
};

export const MARKUP_PERCENT_TYPES = {
  LINEHAUL: 'Linehaul',
  TOTAL: 'Total',
} as const;

export const DATA_TYPES = {
  UPLOAD_FILE: 'Upload File',
  PULL_DATA_RATE: 'Pull Data & Rate',
  PULL_DATA: 'Pull Data',
} as const;

export const BG_BLANKET_ACCOUNT = {
  name: 'All Blankets',
  enterpriseID: 0,
  accountNumber: CONFIG.isDevelopment ? 'StaRo' : 'BGBLKCT',
} as const;
