type FieldValues = Record<string, any>;
type WorkbookColumnBuilders = Record<string, (fieldValues: FieldValues) => any>;
type WorkbookColumns = Partial<Record<keyof WorkbookColumnBuilders, any>>;

export const mapFieldsToWorkbookColumns = <
  TFieldValues extends FieldValues,
  TWorkbookColumnBuilders extends WorkbookColumnBuilders
>(
  fieldValues: TFieldValues,
  workbookColumnBuilders: TWorkbookColumnBuilders
) => {
  return Object.entries(workbookColumnBuilders).reduce((workbook, entry) => {
    const [key, func] = entry;
    const workbookValue = func(fieldValues);

    workbook[key] = workbookValue;

    return workbook;
  }, {} as WorkbookColumns);
};
