import React, { useState } from 'react';
import { Log, UserManager, WebStorageStateStore, User } from 'oidc-client';
import { AuthContext } from './AuthContext';
import { shipmentApi } from '../api';
import { CONFIG } from '../utilities';
import { logger } from '../logging';
import { OPERATIONS, USER_TYPES } from './constants';

export type BlueShipUser = {
  userName: string;
  userID: number;
  userType: string;
  enterpriseID: number;
  name: string;
  email: string;
  operations: string[];
};

const {
  location: { port, protocol, hostname },
} = window;
const baseUrl = `${protocol}//${hostname}${port ? `:${port}` : ''}`;

const oidcConfig = {
  authority: CONFIG?.identityServerUrl,
  client_id: 'tender-ui', // TODO: Change to jarvis-ui
  redirect_uri: `${baseUrl}/signin-callback`,
  silent_redirect_uri: `${baseUrl}/silent-renew.html`,
  post_logout_redirect_uri: baseUrl,
  // TODO: Cannot use oidc-client-ts or the super convenient react-oidc-context
  //       until response_type: "code" is supported by BG identity server.
  response_type: 'id_token token',
  scope: 'openid profile',
  userStore: new WebStorageStateStore({ store: window.localStorage }),
};

Log.logger = console;
Log.level = Log.INFO;

const userManager = new UserManager(oidcConfig);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<BlueShipUser | null>(null);

  React.useEffect(() => {
    userManager.events.addAccessTokenExpired(() => {
      console.log('Logging out due to inactivity...');
      logout();
    });

    (async () => {
      const result = await userManager.getUser();
      if (result) {
        await setBlueshipUser(result);
      }
    })();
  }, []);

  const userHasOperation = (operation: string): boolean => {
    if (!user) return false;
    const operations = user.operations;
    return operations.indexOf(operation) > -1;
  };

  const setBlueshipUser = async (user: User) => {
    const {
      profile: { name },
    } = user;

    try {
      const details = await shipmentApi.getCurrentUser(name!);

      if (!details) {
        alert('no user exists in db');
      }

      if (details?.firstName) {
        setUser({
          userName: details.userName,
          userID: details.userID,
          userType: details.userType,
          enterpriseID: details.enterpriseID,
          name: `${details.firstName} ${details.lastName}`,
          email: details.email,
          operations: details.operations ?? [],
        });

        localStorage.setItem('BGUserId', details.userID.toString());
        localStorage.setItem(
          'BGUserEnterpriseId',
          details.enterpriseID.toString()
        );
        localStorage.setItem('BGUserName', details.userName);
      }
    } catch (e: any) {
      logger.error('Unable to fetch user details', {
        error: { message: e.message, stack: e.stack },
      });
    }
  };

  const login = () => {
    userManager.signinRedirect();
  };

  const logout = () => {
    userManager.signoutRedirect();
  };

  const signinCallback = async () => {
    const result = await userManager.signinRedirectCallback();
    if (!result) {
      logger.error(
        'AuthProvider.tsx: sigininCallback - completeAuthentication failed'
      );
    } else {
      setBlueshipUser(result);
    }
  };

  const isValidUserType =
    CONFIG.isDevelopment ||
    ((user?.userType === USER_TYPES.AGENT ||
      user?.userType === USER_TYPES.BG_EMPLOYEE ||
      user?.userType === USER_TYPES.GROUP) &&
      userHasOperation(OPERATIONS.CAN_ACCESS_JARVIS_PORTAL));

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: Boolean(user),
        isBGEmployee: user?.userType === USER_TYPES.BG_EMPLOYEE,
        isValidUserType,
        user,
        userHasOperation,
        actions: { login, logout },
        signinCallback,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
