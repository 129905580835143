import React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { FieldError, Controller, Control, Path } from 'react-hook-form';

type MultiSelectDropdownFieldProps<TFormValues> = {
  control: Control<TFormValues>;
  error?: FieldError | FieldError[];
  label: string;
  name: Path<TFormValues>;
  options: string[];
  selectAll?: boolean;
  helperText?: string;
  hidden?: boolean;
};

function getOptionStyles(
  value: string,
  fieldValue: readonly string[],
  theme: Theme
) {
  return {
    fontWeight:
      fieldValue.indexOf(value) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export const MultiSelectDropdownField = <
  TFormValues extends Record<string, unknown>
>({
  control,
  error,
  label,
  name,
  options,
  selectAll = false,
  helperText = ' ',
  hidden = false,
}: MultiSelectDropdownFieldProps<TFormValues>) => {
  const theme = useTheme();
  const errorMessages = Array.isArray(error)
    ? error.map((e, idx) => (
        <React.Fragment key={idx}>
          {e.message}
          <br />
        </React.Fragment>
      ))
    : error?.message;

  return hidden ? null : (
    <FormControl fullWidth sx={{ mt: 2 }} error={Boolean(error)}>
      <InputLabel>{label}</InputLabel>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <Select
            multiple
            label={label}
            name={name}
            value={value}
            onChange={(e) => {
              if (selectAll) {
                const targetValue = e.target.value as string[];

                if (
                  targetValue.includes('all') &&
                  targetValue.length < options.length + 1
                ) {
                  e.target.value = options.map((o) => o) as any;
                }

                if (
                  targetValue.includes('all') &&
                  targetValue.length === options.length + 1
                ) {
                  e.target.value = [] as any;
                }
              }
              onChange(e);
            }}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {Array.isArray(selected) ? (
                  selected.map((value: any) => (
                    <Chip key={value} label={value} />
                  ))
                ) : (
                  <Chip label="Unknown" />
                )}
              </Box>
            )}
          >
            {selectAll && (
              <MenuItem value="all">
                <ListItemIcon>
                  <Checkbox
                    checked={
                      options.length > 0 &&
                      Array.isArray(value) &&
                      value.length === options.length
                    }
                  />
                </ListItemIcon>
                <ListItemText primary="Select All" />
              </MenuItem>
            )}
            {options.map((option: any, idx: number) => (
              <MenuItem
                key={idx}
                value={option}
                style={getOptionStyles(option, value as string[], theme)}
              >
                {option}
              </MenuItem>
            ))}
          </Select>
        )}
      />
      <FormHelperText>{errorMessages ?? helperText}</FormHelperText>
    </FormControl>
  );
};
