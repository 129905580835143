import React from 'react';
import { Outlet } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { setPostLoginUri } from './utilities';
import { AccessDenied } from '../pages';

export const RequireAuth = () => {
  const auth = useAuth();

  React.useEffect(() => {
    setPostLoginUri();
  }, []);

  React.useEffect(() => {
    if (
      !auth.isAuthenticated &&
      window.location.pathname !== '/signin-callback'
    ) {
      auth.actions.login();
    }
  }, [auth.isAuthenticated]);

  if (!auth.isAuthenticated) {
    return null;
  }

  if (!auth.isValidUserType) {
    return <AccessDenied />;
  }

  return <Outlet />;
};
