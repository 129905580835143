import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import Box from '@mui/material/Box';

import { Page } from '../components';
import { ROUTES } from '../router';

export const Home = () => <Page render={() => <Content />} />;

const Content = () => (
  <>
    <h2>Rating Portal</h2>
    <p>I want to...</p>
    <div>
      <Box sx={{ mb: 2 }}>
        <Link component={RouterLink} to={ROUTES.UPLOAD_ANALYZE.path}>
          Run an analysis with data that I will upload
        </Link>
      </Box>
      <Box sx={{ mb: 2 }}>
        <Link component={RouterLink} to={ROUTES.PULL_DATA.path}>
          Pull some data
        </Link>
      </Box>
      <Box sx={{ mb: 2 }}>
        <Link component={RouterLink} to={ROUTES.PULL_DATA_ANALYZE.path}>
          Pull some data and run an analysis on it
        </Link>
      </Box>
    </div>
  </>
);
