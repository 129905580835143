import * as React from 'react';
import { useAuth } from '../auth';
import { useNavigate } from 'react-router-dom';
import { utils } from '../auth';
import { Page } from '../components';

export const SigninCallback = () => <Page render={() => <Content />} />;

const Content = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  React.useEffect(() => {
    (async () => {
      try {
        auth.signinCallback();
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  React.useEffect(() => {
    if (auth.isAuthenticated) {
      navigate(utils.getPostLoginUri());
    }
  }, [auth.isAuthenticated, navigate]);

  return <h1>Signing in...</h1>;
};
