import { Control, Path, FieldError } from 'react-hook-form';

import { MultiSelectDropdownField } from '../../components';
import { TIERS } from '../constants';

const carrierTierOptions = Object.values(TIERS);

type CarrierTiersFieldProps<TFormValues> = {
  control: Control<TFormValues>;
  error?: FieldError | FieldError[];
  helperText?: string;
  label?: string;
  name: Path<TFormValues>;
};

export const CarrierTiersField = <TFormValues extends Record<string, unknown>>({
  control,
  error,
  helperText,
  label,
  name,
}: CarrierTiersFieldProps<TFormValues>) => (
  <MultiSelectDropdownField<TFormValues>
    control={control}
    error={error}
    helperText={helperText}
    label={label ?? 'Carrier Tiers'}
    name={name}
    options={carrierTierOptions}
    selectAll
  />
);
