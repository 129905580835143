import { Control, Path, FieldError } from 'react-hook-form';

import { AutocompleteField } from '..';
import { STATE_OPTIONS } from '../../constants';

const stateOptions = STATE_OPTIONS.map((state) => ({
  label: `${state.abbrev} ${state.name}`,
  value: state.abbrev,
  nation: state.nation,
}));

type StatesFieldProps<TFormValues> = {
  control: Control<TFormValues>;
  error?: FieldError | FieldError[];
  helperText?: string;
  hidden?: boolean;
  label: string;
  name: Path<TFormValues>;
};

export const StatesField = <TFormValues extends Record<string, unknown>>({
  control,
  error,
  helperText,
  hidden,
  label,
  name,
}: StatesFieldProps<TFormValues>) => (
  <AutocompleteField<TFormValues, typeof stateOptions[number]>
    control={control}
    error={error}
    getOptionLabel={(option) => {
      if (!option) return '';
      return option.label;
    }}
    groupBy={(option) => option.nation}
    helperText={helperText}
    hidden={hidden}
    isOptionEqualToValue={(option, value) => {
      return value.value === option.value;
    }}
    label={label}
    multiple
    name={name}
    options={stateOptions}
  />
);
