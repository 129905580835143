// Must be kept in sync with Azure Function code
// @see: https://dev.azure.com/bluegrace-vsts/Nebula/_git/BS.RateQuality?path=/BS.RateQuality.Functions/BatchRates/Entities
export const COLUMN_NAMES = {
  ACCOUNT_NAME: 'Account Name',
  ACCOUNT_NUMBER: 'Account Number',
  ANALYSIS_TYPE: 'Analysis Type',
  CARRIER_EXCLUSION: 'Carrier Exclusion',
  CARRIER_INCLUSION: 'Carrier Inclusion',
  CARRIER_TIERS: 'Carrier Tiers',
  IMPACT_CARRIER_EXCLUSIONS: 'Impact Carrier Exclusions',
  IMPACT_ORIGIN_POSTAL_EXCLUSIONS: 'Impact Origin Postal Exclusions',
  IMPACT_ORIGIN_STATE_EXCLUSIONS: 'Impact Origin State Exclusions',
  IMPACT_DESTINATION_POSTAL_EXCLUSIONS: 'Impact Destination Postal Exclusions',
  IMPACT_DESTINATION_STATE_EXCLUSIONS: 'Impact Destination State Exclusions',
  MARKUP_AMOUNT: 'Markup ($ or %)',
  MARKUP_PERCENT_TYPE: 'Markup % Type',
  MARKUP_TYPE: 'Markup Type',
  NOTIFICATION_EMAIL: 'Notification Email',
  SYSTEMS_TO_RATE: 'Systems To Rate',
} as const;
