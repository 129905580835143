import { Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

export const SubmissionSuccess = () => {
  return (
    <Box sx={{ width: '100%' }}>
      <Stack alignItems="center">
        <Typography align="center" component="h2" variant="h4" sx={{ mb: 3 }}>
          Success!
        </Typography>
        <CheckCircleIcon
          sx={{
            fontSize: '80px',
            color: 'success.light',
          }}
        />
      </Stack>
      <Typography sx={{ mt: 4 }}>
        You will receive an email shortly with links to check status or
        terminate your request. This email may take a few minutes to arrive.
      </Typography>
    </Box>
  );
};
