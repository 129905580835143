import { Control, Path, FieldError } from 'react-hook-form';

import { DropdownField } from '../../components';
import { MARKUP_PERCENT_TYPES } from '../constants';
import { constToOption } from '../utilities';

const markupPercentOptions = Object.values(MARKUP_PERCENT_TYPES).map((v) =>
  constToOption(v)
);

type MarkupPercentageTypeFieldProps<TFormValues> = {
  control: Control<TFormValues>;
  error?: FieldError;
  filterOptions?: (
    options: typeof markupPercentOptions
  ) => typeof markupPercentOptions;
  helperText?: string;
  hidden?: boolean;
  label?: string;
  name: Path<TFormValues>;
};

export const MarkupPercentageTypeField = <
  TFormValues extends Record<string, unknown>
>({
  control,
  error,
  filterOptions,
  helperText,
  hidden,
  label,
  name,
}: MarkupPercentageTypeFieldProps<TFormValues>) => (
  <DropdownField<TFormValues>
    control={control}
    error={error}
    helperText={helperText}
    hidden={hidden}
    label={label ?? 'Markup % Type'}
    name={name}
    options={
      filterOptions ? filterOptions(markupPercentOptions) : markupPercentOptions
    }
  />
);
