import { Controller, Control, Path, FieldError } from 'react-hook-form';

import { TextField } from './TextField';

type ZipCodeFieldProps<TFormValues> = {
  control: Control<TFormValues>;
  error?: FieldError;
  hidden?: boolean;
  label: string;
  name: Path<TFormValues>;
};

export const ZipCodeField = <TFormValues extends Record<string, unknown>>({
  control,
  error,
  hidden,
  label,
  name,
}: ZipCodeFieldProps<TFormValues>) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <TextField
          error={error}
          helperText={error?.message ?? 'USA and/or Canada Postal Codes'}
          hidden={hidden}
          label={label}
          onChange={field.onChange}
        />
      )}
    />
  );
};
