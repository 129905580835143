import { Container } from '@mui/material';
import { Box } from '@mui/system';

export const Footer = () => {
  return (
    <footer>
      <Box>
        <Container sx={{ textAlign: 'center', color: '#666' }}>
          © 2022 BlueGrace Logistics LLC. All Rights Reserved
        </Container>
      </Box>
    </footer>
  );
};
