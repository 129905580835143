import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Popover from '@mui/material/Popover';
import HelpIcon from '@mui/icons-material/Help';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import DatePicker, { DatePickerProps } from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import { FieldError, Controller, Control, Path } from 'react-hook-form';
import add from 'date-fns/add';
import sub from 'date-fns/sub';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import type { Duration } from 'date-fns';

import { TODAY } from '../../constants';
import { isValidDateString } from '../../utilities';

type DateRangePickerProps<ControlFields> = {
  startDateOptions?: Partial<DatePickerProps<Date>>;
  endDateOptions?: Partial<DatePickerProps<Date>>;
  error?: {
    startDate?: FieldError;
    endDate?: FieldError;
  };
  name: Path<ControlFields>;
  control: Control<ControlFields>;
  label: string;
  labelHelp?: React.ReactNode;
  helperText?: string;
  hidden?: boolean;
  maxDateSpan?: Duration;
  openTo?: DatePickerProps['openTo'];
};

export const DateRangePickerField = <T extends object>({
  startDateOptions = {},
  endDateOptions = {},
  hidden,
  label,
  labelHelp,
  error,
  name,
  control,
  helperText = ' ',
  maxDateSpan,
  openTo,
}: DateRangePickerProps<T>) => {
  const {
    minDate: startMinDate = TODAY,
    maxDate: startMaxDate,
    label: startDateLabel = 'Start Date',
  } = startDateOptions;
  const {
    label: endDateLabel = 'End Date',
    minDate: endMinDateOption,
    maxDate: endMaxDateOption,
  } = endDateOptions;

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleHelpTextOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleHelpTextClose = () => {
    setAnchorEl(null);
  };

  const isPopoverOpen = Boolean(anchorEl);

  return hidden ? null : (
    <Controller
      control={control}
      name={name}
      render={({
        field,
      }: {
        field: {
          value: { startDate?: Date; endDate?: Date };
          onChange: (value: any) => void;
        };
      }) => {
        const { startDate } = field.value;

        let endMinDate;
        let endMaxDate: Date | undefined;

        // Adjusts user selectable dates based on props: maxDateSpan, startDateOptions, endDateOptions
        if (maxDateSpan) {
          endMinDate = startDate ? startDate : endMinDateOption;
          endMaxDate = startDate
            ? // maxDateSpan is INCLUSIVE of the startDate
              add(sub(startDate, { days: 1 }), maxDateSpan)
            : endMaxDateOption;

          if (isAfter(endMaxDate ?? TODAY, TODAY)) {
            endMaxDate = TODAY;
          }
        }

        return (
          <FormControl fullWidth error={Boolean(error)}>
            <Stack direction="row" alignItems="center" gap={1}>
              <Typography
                variant="body2"
                sx={{
                  mt: 1,
                  mb: 1,
                  color: Boolean(error) ? 'error.main' : 'rgba(0, 0, 0, 0.6)',
                }}
              >
                {label}
              </Typography>
              <Box
                id={`${name}_helper`}
                aria-owns={
                  isPopoverOpen ? 'dropdown-item-mouse-over-popover' : undefined
                }
                aria-haspopup="true"
                onMouseEnter={handleHelpTextOpen}
                onMouseLeave={handleHelpTextClose}
              >
                <HelpIcon
                  fontSize="small"
                  sx={{
                    color: Boolean(error) ? 'error.main' : 'rgba(0, 0, 0, 0.6)',
                  }}
                />
                <Popover
                  id="dropdown-item-mouse-over-popover"
                  open={isPopoverOpen}
                  anchorEl={isPopoverOpen ? anchorEl : null}
                  onClose={handleHelpTextClose}
                  sx={{
                    pointerEvents: 'none',
                  }}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  PaperProps={{
                    sx: {
                      ml: 1,
                      p: 1,
                      maxWidth: '400px',
                    },
                  }}
                >
                  {labelHelp}
                </Popover>
              </Box>
            </Stack>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Grid container>
                <Grid xs={5.5} item>
                  <DatePicker
                    disableHighlightToday
                    minDate={startMinDate}
                    maxDate={startMaxDate}
                    value={field.value.startDate}
                    label={startDateLabel}
                    openTo={openTo}
                    onChange={(
                      newValue: Date | null,
                      dateString: string | undefined
                    ) => {
                      const isAfterEndDate =
                        isValidDateString(dateString) &&
                        isAfter(newValue!, field.value.endDate!);
                      field.onChange({
                        target: {
                          value: {
                            startDate: newValue,
                            endDate: isAfterEndDate
                              ? newValue
                              : field.value.endDate,
                          },
                          name,
                        },
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ mt: 0 }}
                        fullWidth
                        error={params.error || Boolean(error)}
                        helperText={error?.startDate?.message}
                        FormHelperTextProps={{
                          sx: { mr: 0, width: '90%' },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid xs={1} item>
                  <Typography
                    sx={{
                      textAlign: 'center',
                      mt: 2,
                      ml: 0.5,
                      mr: 0.5,
                      color: Boolean(error)
                        ? 'error.main'
                        : 'rgba(0, 0, 0, 0.6)',
                    }}
                  >
                    to
                  </Typography>
                </Grid>
                <Grid xs={5.5} item>
                  <DatePicker
                    disableHighlightToday
                    value={field.value.endDate}
                    label={endDateLabel}
                    minDate={endMinDate}
                    maxDate={endMaxDate}
                    openTo={openTo}
                    onChange={(
                      newValue: Date | null,
                      dateString: string | undefined
                    ) => {
                      const isBeforeStartDate =
                        isValidDateString(dateString) &&
                        isBefore(newValue!, field.value.startDate!);
                      field.onChange({
                        target: {
                          value: {
                            startDate: isBeforeStartDate
                              ? newValue
                              : field.value.startDate,
                            endDate: newValue,
                          },
                          name,
                        },
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ mt: 0 }}
                        fullWidth
                        error={params.error || Boolean(error)}
                        helperText={error?.endDate?.message}
                        FormHelperTextProps={{
                          sx: { mr: 0, width: '90%' },
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </LocalizationProvider>
            <FormHelperText>{helperText}</FormHelperText>
          </FormControl>
        );
      }}
    />
  );
};
