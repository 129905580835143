import MuiTextField, {
  TextFieldProps as MuiTextFieldProps,
} from '@mui/material/TextField';
import { FieldError } from 'react-hook-form';

type TextFieldProps = Omit<MuiTextFieldProps, 'error'> & {
  label: string;
  error?: FieldError;
  helperText?: string;
};

export const TextField = ({
  error,
  helperText = ' ',
  hidden,
  ...rest
}: TextFieldProps) =>
  hidden ? null : (
    <MuiTextField
      fullWidth
      error={Boolean(error)}
      helperText={error?.message ?? helperText}
      {...rest}
    />
  );
