import { Controller, Control, Path, FieldError } from 'react-hook-form';
import InputAdornment from '@mui/material/InputAdornment';

import { TextField } from '../../components';

type MarkupAmountFieldProps<TFormValues> = {
  control: Control<TFormValues>;
  error?: FieldError;
  hidden?: boolean;
  label?: string;
  name: Path<TFormValues>;
  amountType: 'Dollar' | 'Percentage';
};

export const MarkupAmountField = <TFormValues extends Record<string, unknown>>({
  amountType,
  control,
  error,
  hidden,
  label,
  name,
}: MarkupAmountFieldProps<TFormValues>) => (
  <Controller
    control={control}
    name={name}
    render={({ field }) => (
      <TextField
        error={error}
        hidden={hidden}
        onChange={field.onChange}
        inputProps={{
          type: 'number',
        }}
        label={label ?? 'Markup Amount'}
        InputProps={{
          startAdornment: amountType === 'Dollar' && (
            <InputAdornment position="start">$</InputAdornment>
          ),
          endAdornment: amountType === 'Percentage' && (
            <InputAdornment position="end">%</InputAdornment>
          ),
        }}
        helperText={
          error
            ? error.message
            : amountType === 'Percentage'
            ? 'Percentage as a whole number'
            : 'Dollar amount'
        }
      />
    )}
  />
);
